import React, { useEffect } from 'react'
import { Input, Button, Table, Empty, Card } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { formatCurrency } from '../../helper/formatCurrency'
import { set } from 'date-fns'
import ShiftDashboard from './ShiftDashboard'
import { fetchData, postData } from '../../helper/api'
import { toast } from 'react-toastify'
import moment from 'moment'

export default function Index() {
    const [ShiftDashboardOpen, setShiftDashboardOpen] = React.useState(false)

    const [dataShift, setDataShift] = React.useState([])
    const [dataShiftList, setDataShiftList] = React.useState([])

    const formik = useFormik({
        initialValues: {
            cashInDrawer: 0
        },

        onSubmit: values => {
            postData('/shifts', {
                cash_initial: values.cashInDrawer,
            })
                .then((response) => {
                    fetchData('/get-profile')
                        .then((response) => {
                            const data = response.data.data
                            setDataShift(data.shifts)
                            setShiftDashboardOpen(true)
                            formik.setFieldValue('cashInDrawer', 0)
                            toast.success('Shift Opened')
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    })

    useEffect(() => {
        // Get data shift
        fetchData('/shifts')
            .then((response) => {
                const data = response.data.data
                const moveData = data.map((item) => {
                    return {
                        ...item,
                        name: item.employee_shift.name,
                        start_time: moment(item.start_time).format('DD MMM YYYY HH:mm:ss'),
                        end_time: item.end_time ? moment(item.end_time).format('DD MMM YYYY HH:mm:ss') : '-',
                        total_transaction: item.transaction || 0,
                        total_expected: formatCurrency(parseInt(item.cash_expected + item.cash_initial || 0)),
                        total_actual: formatCurrency(parseInt(item.cash_actual || 0)),
                        difference: formatCurrency(parseInt(item.difference || 0)),
                    }
                })
                setDataShiftList(moveData)
            })
            .catch((error) => {
                console.log(error)
            })


        fetchData('/get-profile')
            .then((response) => {
                const data = response.data.data
                setDataShift(data.shifts)
                if (data.shifts.end_time === null) {
                    setShiftDashboardOpen(true)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [ShiftDashboardOpen])

    const columnsShift = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            key: 'end_time',
        },
        {
            title: 'Total Transaction',
            dataIndex: 'total_transaction',
            key: 'total_transaction',
        },
        {
            title: 'Total Expected',
            dataIndex: 'total_expected',
            key: 'total_expected',
        },
        {
            title: 'Total Actual',
            dataIndex: 'total_actual',
            key: 'total_actual',
        },
        {
            title: 'Difference',
            dataIndex: 'difference',
            key: 'difference',
        },
    ]

    return (
        <div>
            {!ShiftDashboardOpen ? (
                <>
                    <h1>Input Cash in Drawer</h1>
                    <Input
                        name="cashInDrawer"
                        value={formatCurrency(parseInt(formik.values.cashInDrawer) || 0)}
                        style={{ width: '20%' }}
                        onChange={(event) => {
                            const value = event.target.value;
                            // Mengabaikan karakter selain digit (contoh: comma atau titik)
                            const numericValue = parseFloat(value.replace(/[^\d]/g, ''));

                            // Menangani nilai awal NaN
                            const newValue = isNaN(numericValue) ? 0 : numericValue;

                            formik.setFieldValue('cashInDrawer', newValue);
                        }}
                    />
                    {formik.touched.cashInDrawer && formik.errors.cashInDrawer ? (
                        <div style={{ color: 'red' }}>{formik.errors.cashInDrawer}</div>
                    ) : null}
                    <div style={{ marginBottom: "40px" }}>
                        <Button className="items-center flex justify-center text-white bg-blue-400 w-[20%] mt-2" onClick={formik.handleSubmit}>Open Shift</Button>
                    </div>
                    <Card>
                    <div className="table-responsive" >
                        <Table
                            columns={columnsShift}
                            dataSource={dataShiftList}
                            className="ant-border-space"
                            locale={{
                                emptyText: <Empty description="Data not found" />,
                            }}
                            pagination={{ 
                                position: ['bottomCenter'],
                                pageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ['5', '10', '20', '30', '40'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                             }}
                        />
                    </div>
                    </Card>
                </>
            ) : (
                <>
                    <ShiftDashboard dataShift={dataShift} setShiftDashboardOpen={setShiftDashboardOpen} />
                </>
            )}

        </div>
    )
}
