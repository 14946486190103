export const ConvertStringToArray = (value) => {
    // Memeriksa apakah value berupa string dan mengandung koma
    if (typeof value === 'string' && value.includes(',')) {
        return value.split(',').map(item => item.trim()); // Memisahkan string menjadi array
    } else {
        // Kembalikan nilai dengan array
        return [value];
    }
};

