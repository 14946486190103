
// Fungsi untuk menghitung diskon
export const calculateDiscount = (orderDetail, processTimeCost = 0) => {
  if (orderDetail.discount_id === 0) return 0;
  const treatmentPrice = parseInt(orderDetail.treatment?.price) + processTimeCost;
  // check if discount type is percentage
  if (orderDetail.discount?.type === 'percent') {
    const discountPrice = treatmentPrice * (orderDetail.discount.discount / 100);
    return discountPrice;
  } else {
    return orderDetail.discount?.discount;
  }
};

export const calculateDiscountedPrice = (price_nominal, discountData) => {
  if (discountData?.type === 'percent') {
    const discount_nominal = price_nominal * (discountData?.discount / 100);
    return {
      final_price: price_nominal - discount_nominal,
      discount_nominal: discount_nominal
    }
  } else {
    return {
      final_price: price_nominal - discountData?.discount,
      discount_nominal: discountData?.discount
    }
  }
}

export const calculateProcessTimeCost = (price, process_time = "regular") => {
  if (process_time === 'regular') return 0;
  if (process_time === 'next_day') return price * 0.5;
  if (process_time === 'same_day') return price * 1;
}