import React, { useState } from 'react'
import { Card, Image } from 'antd'
import { formatNumber } from '../../helper/formatCurrency'
import dayjs from 'dayjs'
import { WashingStatusDisplay } from '../../models/orderData'
import { DisplayProcessTime } from './helper'
import { Radio, Modal, Button } from 'antd'
import { fetchData, putData } from '../../helper/api'
import { toast } from 'react-toastify'



export default function CardItemService({ record }) {

    const [dataRecord, setDataRecord] = React.useState(record)

    const [dateNow, setDateNow] = React.useState(dayjs().format('YYYY-MM-DD'))
    const imgIllustration = "https://static.vecteezy.com/system/resources/previews/003/528/131/original/in-progress-man-run-to-their-goal-illustration-flat-vector.jpg"

    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = (e, id) => {
        console.log('OK KIRIM DATA', dataRecord);

        const newDataRecord = { ...dataRecord }
        const orderDetails = newDataRecord.order_details.map(item => {
            if (item.id === id) {
                return { ...item, status: e.target.value }
            }
            return item
        })
        newDataRecord.order_details = orderDetails
        setDataRecord(newDataRecord)

        try {
            putData(`/order-details/${id}`, {
                status: e.target.value
            }).then(response => {
                toast.success('Berhasil mengubah status order')
            })
        } catch (error) {
            console.log('ERROR', error);
        }
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };


    React.useEffect(() => {
        setDataRecord(record)
    }, [record])

    const handleStatusChange = (e, id) => {

        Modal.confirm({
            title: `Apakah anda yakin ingin mengubah status order menjadi "${WashingStatusDisplay(e.target.value)}"?`,
            footer: null,
            okText: 'Ya',
            cancelText: 'Tidak',
            onOk: () => handleOk(e, id),
            onCancel: handleCancel
        });


    }

    return (
        <>
            <Modal
                open={open}
                title="Title"
                footer={null}
            // onOk={handleOk}
            // onCancel={handleCancel}
            // footer={false}
            >
            </Modal>
            <div style={{ display: "flex", flexDirection: "column", gap: '5px', padding: '5px', borderRadius: '15px', backgroundColor: `${dataRecord?.payment?.status === 'paid' ? '#90ee90c4' : '#fff200'}` }}>
                {dataRecord?.order_details?.map((item, index) => {
                    return (
                        <Card key={index} style={{
                            padding: '8px', width: "100%", backgroundColor: `
                            ${item.status === 'done' ? '#90ee90c4' : item.due_date <= dateNow ? '#d10000ad' :
                                    item.status === 'siap' ? '#f0e68c' : '#ffffff'
                                }`
                        }}>
                            <div style={{ display: "flex", gap: '10px', color: "black", alignItems: 'center' }}>
                                <Image src={item?.shoes_photos[0]?.url_photo || "https://th.bing.com/th/id/OIP.cY6hooCAv2-CFp6BL6Tp3gHaHa?rs=1&pid=ImgDetMain"}
                                    style={{
                                        width: '80px', height: '50px', objectFit: 'cover', objectPosition: 'center center'
                                    }} />

                                <div style={{ display: "flex", flexDirection: 'column', gap: '0px', justifyContent: "space-around", width: "100%" }}>
                                    <div>
                                        <p style={{ color: "black", fontWeight: 'bold' }}>{item.treatment.name} - {DisplayProcessTime(item.process_time)}</p>
                                    </div>
                                    <div style={{ display: "flex", gap: '5px', justifyContent: "space-between", padding: '4px', width: "100%" }}>
                                        <div>
                                            <p style={{ fontSize: '10px', color: "black" }}>{item?.item?.name} - {item?.item?.color}</p>
                                            <p style={{ fontSize: '10px', color: 'black' }}> Due Date: <p style={{ fontSize: '12px', color: 'black', fontWeight: 'bold' }}>{dayjs(item.due_date).format('DD MMM YYYY')}</p></p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: '10px', color: 'black' }}> Status: <p style={{ fontSize: '10px', color: 'black', fontWeight: 'bold' }}>{WashingStatusDisplay(item.status)}</p></p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: '12px', color: "black" }}>{dataRecord?.payment?.status === 'paid' ? "Paid" : "Unpaid"}</p>
                                            <p style={{ fontSize: '15px', fontWeight: 'bold', color: "black" }}>{formatNumber(parseInt(item.final_price))}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex gap-2'>
                                <p className='font-bold' style={{ fontSize: '14px', color: 'black' }}> Catatan: </p>
                                <p className='' style={{ fontSize: '14px', color: 'black' }}> {item.description || '-'}</p>
                            </div>
                            <div className='w-full'>
                                <p className='font-bold' style={{ fontSize: '12px', color: 'black' }}> Status: </p>
                                <Radio.Group onChange={(e) => handleStatusChange(e, item.id)} value={item.status}>
                                    <Radio className='text-black scale-75' value='not_yet'>Antrian</Radio>
                                    <Radio className='text-black scale-75' value='on_progress'>Proses</Radio>
                                    <Radio className='text-black scale-75' value='pengeringan'>Pengeringan</Radio>
                                    <Radio className='text-black scale-75' value='siap'>Siap Diambil</Radio>
                                    <Radio className='text-black scale-75' value='done'>Selesai</Radio>
                                    <Radio className='text-black scale-75' value='cancelled'>Batal</Radio>
                                </Radio.Group>
                            </div>
                        </Card>
                    )
                })}
            </div>
        </>
    )

}
