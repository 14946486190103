import Routes from "./route";
import React from 'react';
import { useState } from "react";
import { UserDataModel } from "./models/userData";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// buat context manual
export const AllDataContext = React.createContext();

export function useData() {
  return React.useContext(AllDataContext);
}

function App() {
  const [data, setData] = useState({
    users: UserDataModel,
  });

  const addUser = (user) => {
    setData((prevData) => ({
      ...prevData,
      users: [user],
    }));
  };


  return (
    <div className="App">
      <ToastContainer limit={3} autoClose={3000} />
      <AllDataContext.Provider value={{ data, addUser }}>
        <Routes />
      </AllDataContext.Provider>
    </div>
  );
}

export default App;
