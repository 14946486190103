import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helper/formatCurrency';
import numeral from 'numeral';
import { useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

const { Option } = Select;

const ModalAddTreatment = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {
    const location = useLocation();
    const history = useHistory();

    const [form] = Form.useForm();

    const handleAddTreatment = () => {
        form.validateFields().then((values) => {
            if (initialData) {
                putData(`/treatments/${initialData.id}`, {
                    ...values,
                    name: values.type_item + " - " + values.type_service,
                    price: numeral(values.price).value(),
                })
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('Treatment updated successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/treatments', {
                    ...values,
                    name: values.type_item + " - " + values.type_service,
                    price: numeral(values.price).value(),
                })
                    .then((response) => {
                        if (response.status === 201) {
                            toast.success('Treatment added successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    };

    useEffect(() => {
        if (initialData) {
            form.setFieldsValue({
                name: initialData.name,
                price: numeral(initialData.price).format('0,0'),
                type_item: initialData.type_item,
                type_service: initialData.type_service,
                process_time: initialData.process_time,
                description: initialData.description,
            });
        }
    }, [initialData]);

    return (
        <Modal
            title="Add Treatment"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form form={form} onFinish={handleAddTreatment}>
                {/* <Form.Item
                    label="Treatment Name"
                    name="name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the treatment name' }]}
                >
                    <Input placeholder="Enter treatment name" />
                </Form.Item> */}


                <Form.Item
                    label="Type Item"
                    name="type_item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please select the type item' }]}
                >
                    <Input placeholder="Enter type item" />
                    {/* <Select placeholder="Select type item">
                        <Option value="Shoes">Shoes</Option>
                        <Option value="ShoesKids">Shoes Kids</Option>
                        <Option value="BagSmall">Small Bag</Option>
                        <Option value="BagMedium">Medium Bag</Option>
                        <Option value="BagLarge">Large Bag</Option>
                    </Select> */}
                </Form.Item>

                <Form.Item
                    label="Type Service"
                    name="type_service"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please select the type wash' }]}
                >
                    <Input placeholder="Enter type service" />
                    {/* <Select placeholder="Select type service">
                        <Option value="DeepClean">Deep Clean</Option>
                        <Option value="OutsideClean">Outside Clean</Option>
                        <Option value="Reglue">Reglue</Option>
                        <Option value="Repair">Repair</Option>
                        <Option value="Repaint">Repaint</Option>
                    </Select> */}
                </Form.Item>

                <Form.Item
                    label="Price"
                    name="price"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the price' }]}
                >
                    <Input
                        type="text"
                        placeholder="Enter price"
                        onChange={(e) => {
                            const formattedValue = numeral(e.target.value).format('0,0');
                            form.setFieldsValue({ price: formattedValue });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the description' }]}
                >
                    <Input.TextArea placeholder="Enter description" />
                </Form.Item>

                <Form.Item>
                    <div className='flex justify-end'>
                        <Button
                            type="default"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button className="items-center flex justify-center text-white bg-blue-400" htmlType="submit">
                            {initialData ? 'Update' : 'Add'}
                        </Button>
                    </div>

                </Form.Item>
            </Form>
        </Modal >
    );
};

export default ModalAddTreatment;
