import React, { useEffect } from 'react';
import { Form, Input, Button, Select, Upload, DatePicker } from 'antd';
import { TRANSACTION_DETAIL_OPTION } from './helper';
import ModalAddItem from '../Item/ModalAddItem';
import { WashingStatusOption, fileList } from '../../models/orderData';
import { UploadOutlined } from '@ant-design/icons';
import UploadItem from './UploadItem';
import { fetchData } from '../../helper/api';
import { formatCurrency } from '../../helper/formatCurrency';
import { calculateDiscount, calculateProcessTimeCost, calculateTotalPrice } from '../../helper/Calculation';
import UploadDraggerItem from './UploadDraggerItem';
import moment from 'moment';
import { PROCESS_TIME_OPTIONS } from '../../models/itemData';

const { Option } = Select;

function AddItem(props) {
    const { formik, index, selectTransactionDetailOption, setSelectTransactionDetailOption, initialData } = props;

    const [ModalOpenItem, setModalOpenItem] = React.useState(false);
    const [ModalOpenDelivery, setModalOpenDelivery] = React.useState(false);

    // useState Employee
    const [employeeDataOption, setEmployeeDataOption] = React.useState([]);

    // Treatment
    const [treatmentDataOption, setTreatmentDataOption] = React.useState([]);

    // Item
    const [itemDataOption, setItemDataOption] = React.useState([]);
    const [listDataItem, setListDataItem] = React.useState([]);

    // Discount
    const [discountDataOption, setDiscountDataOption] = React.useState([]);
    const [discount, setDiscount] = React.useState(0);
    const [price, setPrice] = React.useState(0);

    // Process Time
    const [processTimeDataOption, setProcessTimeDataOption] = React.useState(PROCESS_TIME_OPTIONS);
    const [selectProcessTime, setSelectProcessTime] = React.useState(PROCESS_TIME_OPTIONS[0]);
    const [finalPrice, setFinalPrice] = React.useState(0);


    useEffect(() => {
        console.log("FORMIK ", formik.values.order_details[index])
        // Get Data Employee
        fetchData('/users')
            .then((response) => {
                const data = response.data.data;
                const options = data.map((item) => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                }));
                setEmployeeDataOption(options);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get Data Treatment  
        fetchData('/treatments')
            .then((response) => {
                const data = response.data.data;
                const options = data.map((item) => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                }));
                setTreatmentDataOption(options);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get Data Item
        fetchData('/items')
            .then((response) => {
                const data = response.data.data;
                const options = data.map((item) => ({
                    label: `${item.brand} - ${item.name} - ${item.color}`,
                    value: item.id,
                    ...item,
                }));
                setItemDataOption(options);
                setListDataItem(data);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get Data Discount
        fetchData('/discounts')
            .then((response) => {
                const data = response.data.data;
                const options = data.map((item) => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                }));
                setDiscountDataOption(options);
            })
            .catch((error) => {
                console.log(error);
            });

    }, [ModalOpenItem]);

    useEffect(() => {
        formik.setFieldValue(`order_details[${index}].price`, parseInt(formik.values.order_details[index].treatment?.price) || 0);
    }, [formik.values.order_details[index].treatment_id, formik.values.order_details[index].discount_id]);


    const FinalPriceCalculation = () => {
        const price = parseInt(formik.values.order_details[index].treatment?.price) || 0;
        const processTimeCost = calculateProcessTimeCost(price, formik.values.order_details[index]?.process_time)
        const discount = calculateDiscount(formik.values.order_details[index], processTimeCost) || 0;
        const finalPrice = price - discount + processTimeCost;

        formik.setFieldValue(`order_details[${index}].final_price`, finalPrice);
        formik.setFieldValue(`order_details[${index}].process_time_cost`, processTimeCost);
    }

    useEffect(() => {
        setFinalPrice(formik.values.order_details[index].final_price);
        FinalPriceCalculation();
    }, [formik.values.order_details[index].process_time, formik.values.order_details[index].discount_id, formik.values.order_details[index].treatment_id]);

    const handleDelete = (index) => {
        if (selectTransactionDetailOption === TRANSACTION_DETAIL_OPTION.EDIT_ITEM) {
            setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.ITEMS);
            return;
        }
        const values = [...formik.values.order_details];
        values.splice(index, 1);
        formik.setFieldValue('order_details', values);
        setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.ITEMS);
    }

    return (
        <div>
            <Form>
                <div style={{ marginBottom: '10px' }}>
                    <label style={{ fontWeight: 'bold' }}>Item {index + 1}</label>
                </div>

                {formik.values.id > 1 && (
                    <>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].url_photo && 'error'}>
                            <div><label>Upload Photo : </label></div>
                            <UploadDraggerItem formik={formik} index={index} />
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].employee_id && 'error'}>
                            <div><label>Employee : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a employee"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].employee_id}
                                onChange={(value) => { formik.setFieldValue(`order_details[${index}].employee_id`, value) }}
                            >
                                {employeeDataOption.map((item) => (
                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].treatment_id && 'error'}>
                            <div><label>Treatment : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a treatment"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].treatment_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].treatment_id`, value)
                                    formik.setFieldValue(`order_details[${index}].treatment`, option.item)
                                }}
                            >
                                {treatmentDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].item_id && 'error'}>
                            <div><label>Item : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a item"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].item_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].item_id`, value)
                                    formik.setFieldValue(`order_details[${index}].item`, option.item)
                                }}
                            >
                                {itemDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>

                            <Button type="link" onClick={() => setModalOpenItem(true)}>
                                New Item
                            </Button>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].process_time && 'error'}>
                            <div><label>Process Time : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a process time"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].process_time}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].process_time`, value)
                                    formik.setFieldValue(`order_details[${index}].process_time_cost`, option.item.percent * formik.values.order_details[index]?.treatment?.price)
                                }}
                            >
                                {processTimeDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].due_date && 'error'}>
                            <div><label>Due Date : </label></div>
                            <DatePicker
                                style={{ width: '100%' }}
                                format={"dddd, DD MMMM YYYY"}
                                value={formik.values.order_details[index].due_date ? moment(formik.values.order_details[index].due_date) : null}
                                onChange={(value) => { formik.setFieldValue(`order_details[${index}].due_date`, value) }}
                            />
                        </Form.Item>

                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].discount_id && 'error'}>
                            <div><label>Discount : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a discount"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].discount_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].discount_id`, value || null)
                                    formik.setFieldValue(`order_details[${index}].discount`, option.item)
                                }}
                            >
                                {discountDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].price && 'error'}>
                            <div><label>Price : </label></div>
                            <Input
                                type='text'
                                disabled
                                value={formatCurrency(parseInt(formik.values.order_details[index].treatment?.price || 0))}
                            />
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].final_price && 'error'}>
                            <div><label>Final Price : </label></div>
                            <Input
                                type='text'
                                disabled
                                value={formatCurrency(parseInt(formik.values.order_details[index].final_price || 0))}
                            />
                        </Form.Item>

                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].washing_status && 'error'}>
                            <div><label>Status : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a status"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].status}
                                onChange={(value) => { formik.setFieldValue(`order_details[${index}].status`, value) }}
                            >
                                {WashingStatusOption.map((item) => (
                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].description && 'error'}>
                            <div><label>Description : </label></div>
                            <Input
                                type='text'
                                value={formik.values.order_details[index].description}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    formik.setFieldValue(`order_details[${index}].description`, value);
                                }}
                            />
                        </Form.Item>
                    </>
                )}

                {formik.values.id < 1 && (
                    <>
                    <p className='italic mb-3 text-red-600'>Wajib mengisi Treatment, Process Time, Discount</p>
                    <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].treatment_id && 'error'}>
                            <div><label>*Treatment : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a treatment"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].treatment_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].treatment_id`, value)
                                    formik.setFieldValue(`order_details[${index}].treatment`, option.item)
                                }}
                            >
                                {treatmentDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].process_time && 'error'}>
                            <div><label>*Process Time : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a process time"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].process_time}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].process_time`, value)
                                    formik.setFieldValue(`order_details[${index}].process_time_cost`, option.item.percent * formik.values.order_details[index]?.treatment?.price)
                                }}
                            >
                                {processTimeDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].discount_id && 'error'}>
                            <div><label>*Discount : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a discount"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].discount_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].discount_id`, value || null)
                                    formik.setFieldValue(`order_details[${index}].discount`, option.item)
                                }}
                            >
                                {discountDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].description && 'error'}>
                            <div><label>Notes (<span className='italic text-red-600'>Wajib diisi jika ada kerusakan</span>) : </label></div>
                            <Input
                                type='text'
                                value={formik.values.order_details[index].description}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    formik.setFieldValue(`order_details[${index}].description`, value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].price && 'error'}>
                            <div><label>Price : </label></div>
                            <Input
                                type='text'
                                disabled
                                value={formatCurrency(parseInt(formik.values.order_details[index].treatment?.price || 0))}
                            />
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].final_price && 'error'}>
                            <div><label>Final Price : </label></div>
                            <Input
                                type='text'
                                disabled
                                value={formatCurrency(parseInt(formik.values.order_details[index].final_price || 0))}
                            />
                        </Form.Item>

                    </>
                )}
                <div style={{ marginBottom: '10px', display: 'flex', gap: "10px", justifyContent: "flex-end" }}>
                    <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.ITEMS)}>
                        Submit
                    </Button>
                    <Button type="dash" htmlType="button" className="login-form-button" onClick={() => handleDelete()}>
                        Cancel
                    </Button>
                </div>
            </Form >
            <ModalAddItem listData={listDataItem} open={ModalOpenItem} setOpen={setModalOpenItem} />
        </div >
    );
}

export default AddItem;