import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { fetchData, postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { ConvertStringToArray } from '../../helper/convertStringToArray';
import { useFormik } from 'formik';

const { Option } = Select;

const ModalAddItem = ({ open, setOpen, initialData, listData, trigerFetchData, setTrigerFetchData }) => {

    const [BrandOption, setBrandOption] = React.useState([]);
    const [ColorOption, setColorOption] = React.useState([]);
    const [TypeOption, setTypeOption] = React.useState([]);
    const [MaterialOption, setMaterialOption] = React.useState([]);

    const formik = useFormik({
        initialValues: {
            name: '',
            brand: [],
            color: [],
            // type: [],
            // material: [],
        },
        onSubmit: values => {
            const colorAsString = Array.isArray(values.color) ? values.color.join(', ') : values.color;
            const brandAsString = Array.isArray(values.brand) ? values.brand.join(', ') : values.brand;
            // const typeAsString = Array.isArray(values.type) ? values.type.join(', ') : values.type;
            // const materialAsString = Array.isArray(values.material) ? values.material.join(', ') : values.material;

            const newData = {
                name: values.name,
                brand: brandAsString,
                color: colorAsString,
                // type: typeAsString,
                // material: materialAsString,
            }
            if (initialData) {
                putData(`/items/${initialData.id}`, newData)
                    .then((response) => {
                        toast.success('Item updated successfully');
                        setOpen(false);
                        formik.resetForm();
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/items', newData)
                    .then((response) => {
                        toast.success('Item added successfully');
                        setOpen(false);
                        formik.resetForm();
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    })



    useEffect(() => {
        const uniqueOptions = (listData, key) => {
            const uniqueSet = new Set(listData?.map(item => item[key])); // Menggunakan Set untuk nilai unik
            return Array.from(uniqueSet).map(value => ({ value }));
        };

        const brandOption = uniqueOptions(listData, 'brand');
        setBrandOption(brandOption);

        const colorOption = uniqueOptions(listData, 'color');
        setColorOption(colorOption);

        // const typeOption = uniqueOptions(listData, 'type');
        // setTypeOption(typeOption);

        // const materialOption = uniqueOptions(listData, 'material');
        // setMaterialOption(materialOption);

        console.log('brandOption', brandOption);


        if (initialData) {
            formik.setValues(
                {
                    name: initialData?.name,
                    brand: ConvertStringToArray(initialData?.brand),
                    color: ConvertStringToArray(initialData?.color),
                    // type: ConvertStringToArray(initialData?.type),
                    // material: ConvertStringToArray(initialData?.material),
                }
            );
        } 
    }, [initialData, listData]);

    return (
        <Modal
            title="Add Item"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form layout="vertical">
                <Form.Item
                    label="Name"
                >
                    <Input
                        placeholder="Enter item name"
                        value={formik.values?.name || ''}
                        onChange={(e) => {
                            formik.setFieldValue('name', e.target.value);
                        }}
                    />
                </Form.Item>


                <Form.Item
                    label="Brand"
                >
                    <Select
                        mode='tags'
                        maxTagCount={1}
                        placeholder="Select brand"
                        value={formik.values?.brand}
                        onChange={(value) => {
                            formik.setFieldValue('brand', value);
                        }}
                    >
                        {BrandOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Color"
                >
                    <Select
                        mode='tags'
                        maxTagCount={3}
                        placeholder="Select color"
                        value={formik.values?.color}
                        onChange={(value) => {
                            formik.setFieldValue('color', value);
                        }}
                    >
                        {ColorOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                {/* <Form.Item
                    label="Type"
                >
                    <Select
                        mode='tags'
                        maxTagCount={1}
                        placeholder="Select type"
                        value={formik.values?.type}
                        onChange={(value) => {
                            formik.setFieldValue('type', value);
                        }}
                    >
                        {TypeOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Material"
                >
                    <Select
                        mode='tags'
                        maxTagCount={1}
                        placeholder="Select material"
                        value={formik.values?.material}
                        onChange={(value) => {
                            formik.setFieldValue('material', value);
                        }}
                    >
                        {MaterialOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item> */}

                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='flex gap-2'>
                        <Button
                            type="default"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                        loading={formik.isSubmitting}
                        className="items-center flex justify-center text-white bg-blue-400" htmlType="submit" onClick={formik.handleSubmit}>
                            {initialData ? 'Update' : 'Add'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default ModalAddItem;
