import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker, Button, Radio, Card, Col, Row, Typography } from 'antd';
import { ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import ModalAddCustomer from '../component/ModalAddCustomer';
import { API_URL, fetchData, postData, putData } from '../../helper/api';
import { customerDataModel } from '../../models/customerData';
import { optionRadioPaymentMethod, optionRadioPaymentStatus, optionRadioTransaction } from '../../models/generalData';
import * as dayjs from 'dayjs'
import { FormikDataTransactionToPostRequest, FormikDataTransactionToUpdateRequest, InitialDataToFormikDataTransaction, PostDataTransactionModel } from '../../models/orderData';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { MdDelete } from 'react-icons/md';
import AddItem from './AddItem';
import { DisplayProcessTime, TRANSACTION_DETAIL_OPTION, TRANSACTION_PAYMENT_OPTION, TRANSACTION_RADIO_OPTION, validationSchema } from './helper';
import { formatCurrency, formatNumber } from '../../helper/formatCurrency';
import { generateOrderCode } from '../../helper/generateCode'
import KatsikatLogo from '../../assets/images/KatsikatLogo.png'
import generatePDF from 'react-to-pdf';
import Invoice from '../component/pdf/Invoice';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import UploadPhotoEvidence from './UploadPhotoEvidence';
import { InvoiceDataGenerate, PrintInvoice } from '../Printer/PrintInvoice';
import { PrintChecklist } from '../Printer/CetakChecklist';

const { Meta } = Card;

const { Option } = Select;



const ModalAddTransaction = (props) => {
    const { open, setOpen, initialData } = props;
    const location = useLocation();
    const history = useHistory();
    const [selectRadio, setSelectRadio] = useState(TRANSACTION_RADIO_OPTION.TRANSACTION);
    const [selectTransactionDetailOption, setSelectTransactionDetailOption] = useState(TRANSACTION_DETAIL_OPTION.ITEMS);
    const [indexEditItem, setIndexEditItem] = useState(null);
    const [selectPaymentStatus, setSelectPaymentStatus] = useState(TRANSACTION_PAYMENT_OPTION.PAID);

    const [openModalAddCustomer, setOpenModalAddCustomer] = useState(false);
    const [openModalInvoice, setOpenModalInvoice] = useState(false);
    const [dataTransaction, setDataTransaction] = useState(PostDataTransactionModel)

    // useState save data sementara
    const [dataCustomer, setDataCustomer] = useState({ ...customerDataModel });

    // Option Data Customer
    const [dataCustomerOption, setDataCustomerOption] = useState([]);

    const formik = useFormik({
        initialValues: PostDataTransactionModel,
        value: PostDataTransactionModel,
        // validationSchema: validationSchema,
        onSubmit: (values) => {
            if (initialData) {
                const dataPostTransaction = FormikDataTransactionToUpdateRequest(values);
                if (dataPostTransaction.isError) {
                    toast.error(dataPostTransaction.errorMessages[0])
                    formik.setSubmitting(false);

                    return
                }
                putData(`/orders/${initialData.id}`, dataPostTransaction.data)
                    .then((res) => {
                        console.log(res);
                        setOpen(false);
                        formik.resetForm();
                        toast.success("Update Transaction Success");
                        const newLocation = {
                            ...location,
                            search: location.search + `&update=${initialData.id}`,
                        };
                        history.push(newLocation);
                    })
                    .catch((err) => {
                        console.log(err);
                        formik.setSubmitting(false);


                        toast.error("Update Transaction Failed");
                    })
            } else {
                const dataPostTransaction = FormikDataTransactionToPostRequest(values);
                if (dataPostTransaction.isError) {
                    toast.error(dataPostTransaction.errorMessages[0])
                    formik.setSubmitting(false);

                    return
                }
                postData("/orders", dataPostTransaction.data)
                    .then((res) => {

                        console.log(res);

                        formik.setValues(PostDataTransactionModel)
                        setOpen(false);
                        formik.resetForm();

                        toast.success("Add Transaction Success");
                        const newLocation = {
                            ...location,
                            search: location.search + `&add=${res.data.data.id}`,
                        };
                        history.push(newLocation);

                    })
                    .catch((err) => {
                        console.log(err);
                        formik.setSubmitting(false);

                        toast.error("Add Transaction Failed");
                    })
            }

        },
    });

    useEffect(() => {
        if (!open) {
            formik.setValues(PostDataTransactionModel);
        }
        if (initialData) {
            formik.setValues(InitialDataToFormikDataTransaction(initialData));
            setSelectPaymentStatus(initialData.payment?.status);
        } else {
            formik.setValues(PostDataTransactionModel);
        }
    }, [initialData, open]);

    useEffect(() => {
        // fetch data customer
        fetchData("/customers")
            .then((res) => {
                const dataCustomerList = res.data.data;
                const dataCustomerOptionTemp = [];
                dataCustomerList.map((item) => {
                    dataCustomerOptionTemp.push({
                        id: item.id,
                        name: item.name,
                        phone: item.phone,
                        ...item,
                    });
                });
                setDataCustomerOption(dataCustomerOptionTemp);
            })
            .catch((err) => {
                console.log("ERROR");
            });
    }, [openModalAddCustomer]);

    useEffect(() => {
        if (selectRadio === TRANSACTION_RADIO_OPTION.PAYMENT) {
            // set field payment
            const total_price = formik.values.order_details.reduce((total, item) => {
                const price = parseFloat(item.price);
                return !isNaN(price) ? total + price : total;
            }, 0);

            const total_cost_processing_time = formik.values.order_details.reduce((total, item) => {
                const process_time_cost = parseFloat(item.process_time_cost);
                return !isNaN(process_time_cost) ? total + process_time_cost : total;
            }, 0);

            const total_final_price = formik.values.order_details.reduce((total, item) => {
                const final_price = parseFloat(item.final_price);
                return !isNaN(final_price) ? total + final_price : total;
            }, 0);

            formik.setFieldValue('payment', {
                ...formik.values.payment,
                code: formik.values?.payment?.code,
                total_process_time_cost: total_cost_processing_time,
                total_price: total_price + total_cost_processing_time,
                total_discount: total_final_price - total_price - total_cost_processing_time,
                final_price: total_final_price,
            });

            if (selectPaymentStatus === TRANSACTION_PAYMENT_OPTION.UNPAID) {
                formik.setFieldValue('payment', {
                    ...formik.values.payment,
                    code: formik.values?.payment?.code,
                    total_process_time_cost: total_cost_processing_time,
                    total_price: total_price + total_cost_processing_time,
                    total_discount: total_final_price - total_price - total_cost_processing_time,
                    final_price: total_final_price,
                    status: 'unpaid',
                    method: '',
                    date: '',
                    money: '',
                    change: '',
                });
            }
        }
    }, [selectRadio, selectPaymentStatus]);


    const handleAddItem = () => {
        formik.setFieldValue('order_details', [...formik.values.order_details, {
            id: '0',
            order_id: initialData ? initialData.id : '',
            employee_id: '',
            treatment_id: '',
            item_id: '',
            discount_id: '',
            due_date: "",
            price: '',
            final_price: '',
            status: '',
            description: '',
            shoes_photos: [
            ],
        }]);

        setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.ADD_ITEM)
    }
    const handleDelete = (index) => {
        const values = [...formik.values.order_details];
        values.splice(index, 1);
        formik.setFieldValue('order_details', values);
    }

    const handleEdit = (index) => {
        setIndexEditItem(index);
        setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.EDIT_ITEM)
    }

    return (
        <div>
            <Modal
                title="Add Transaction"
                open={open}
                onOk={() => { }} // Handle submit function
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <Radio.Group
                    buttonStyle="solid"
                    style={{ marginBottom: "20px", width: "100%" }}
                    value={selectRadio}
                    onChange={(e) => {
                        setSelectRadio(e.target.value);
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', width: "100%" }}>
                        {optionRadioTransaction.map((item) => {
                            return (
                                <Radio.Button value={item.value} style={{ width: "100%" }}>
                                    {item.label}
                                </Radio.Button>
                            );
                        })}
                    </div>
                </Radio.Group>

                {selectRadio === TRANSACTION_RADIO_OPTION.TRANSACTION &&
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Item validateStatus={formik.errors.customer && 'error'}>
                            <label>Customer : </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a customer"
                                optionFilterProp="children"
                                value={formik.values.customer_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue("customer_id", value)
                                    formik.setFieldValue("customer", option.item)
                                }}
                            >
                                {dataCustomerOption.map((item) => {
                                    return (
                                        <Option item={item} value={item.id}>{item.name} - {item.phone}</Option>
                                    );
                                })}
                            </Select>
                            {/* <ErrorMessage name="customer_id" component="div" className="error-message" /> */}
                            <Button type="link" onClick={() => setOpenModalAddCustomer(true)}>
                                New Customer
                            </Button>

                        </Form.Item>

                        {/* Entry Date */}
                        <Form.Item validateStatus={formik.errors.entry_date && 'error'}>
                            <div><label >Entry Date : </label></div>
                            <DatePicker
                                style={{ width: '100%' }}
                                format={"dddd, DD MMMM YYYY"}
                                value={formik.values.entry_date ? moment(formik.values.entry_date) : null}
                                onChange={(value) => { formik.setFieldValue("entry_date", value) }}
                            />
                        </Form.Item>

                        {/* Qty Item */}
                        <Form.Item validateStatus={formik.errors.quantity && 'error'}>
                            <div><label>Qty Item : </label></div>
                            <Input
                                type='number'
                                value={formik.values.quantity}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    formik.setFieldValue("quantity", value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_type && 'error'}>
                            <div><label>Order Type : </label></div>
                            <Select placeholder="Select order type" value={formik.values.order_type} onChange={(value) => { formik.setFieldValue("order_type", value) }}>
                                <Option value="online">Online</Option>
                                <Option value="offline">Offline</Option>
                            </Select>
                        </Form.Item>


                    </Form>
                }
                {selectRadio === TRANSACTION_RADIO_OPTION.TRANSACTION_DETAIL &&
                    <div>
                        {selectTransactionDetailOption === TRANSACTION_DETAIL_OPTION.ITEMS &&
                            <>
                                <Button type="dashed" style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }} onClick={() => handleAddItem()}>
                                    Add Item
                                </Button>
                                {formik.values.order_details.reverse().map((item, index) => {
                                    // except id -1
                                    if (item.id === '-1') return null;
                                    else return (
                                        <Card
                                            key={index}
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            actions={[
                                                <EditOutlined key="edit" style={{ color: 'blue', fontSize: '20px' }} onClick={() => handleEdit(index)} />,
                                                <div className='flex justify-center items-center text-center'><MdDelete key="delete" style={{ color: 'red', fontSize: '20px' }} onClick={() => handleDelete(index)} /></div>,
                                            ]}
                                        >
                                            <div >
                                                <p className='font-bold mb-2 text-lg'>Service & Item #{formik.values.order_details.length - index}/{formik.values.order_details.length}</p>
                                            </div>

                                            <div style={{ display: "flex" }}>
                                                {/* Photo item */}
                                                {formik.values.id > 1 && (
                                                    <Meta
                                                        avatar={
                                                            <img
                                                                style={{
                                                                    width: '200px', height: '100px', objectFit: 'cover', // Menutupi ukuran container dengan memotong bagian dari gambar
                                                                    objectPosition: 'center center', borderRadius: '8px'
                                                                }}
                                                                alt="example"
                                                                src={item.shoes_photos[0]?.url_photo ? item.shoes_photos[0]?.url_photo : "https://th.bing.com/th/id/OIP.cY6hooCAv2-CFp6BL6Tp3gHaHa?rs=1&pid=ImgDetMain"}
                                                            />
                                                        }
                                                    />)}
                                                <div style={{ marginLeft: '6px', flexGrow: '1' }}>
                                                    {formik.values.id < 1 && (
                                                        <>
                                                            <h3 className='text-lg mt-3' style={{ fontWeight: "bold" }} >{formik.values.order_details[index]?.treatment?.name}</h3>

                                                            <h3 className='text-md'>Waktu Pengerjaan : {DisplayProcessTime(formik.values.order_details[index]?.process_time)}</h3>
                                                            <h3 className='text-md'>Diskon : {formik.values.order_details[index]?.discount?.label}</h3>
                                                            <h3 className='text-md'>Catatan : {formik.values.order_details[index]?.description}</h3>
                                                        </>
                                                    )}
                                                    {formik.values.id > 1 && (
                                                        <>
                                                            <h3 style={{ fontWeight: "bold" }} level={5}>{formik.values.order_details[index]?.treatment?.name}</h3>
                                                            <p style={{ marginBottom: "0px" }}>{formik.values.order_details[index].item?.name} - {formik.values.order_details[index].item?.color}</p>
                                                            <p style={{ marginBottom: "10px" }} ellipsis={{ rows: 2, expandable: true }}>{formik.values.order_details[index].description}</p>
                                                            <div>
                                                                <p style={{ fontWeight: "bold", marginBottom: '0px' }}>Due Date: </p>
                                                            </div>
                                                            <p>{dayjs(formik.values.order_details[index].due_date).format("dddd, DD/MM/YYYY")}</p>
                                                        </>
                                                    )}
                                                </div>
                                                {/* Bagian kanan (harga) */}
                                                <div style={{ textAlign: 'right', display: "flex", flexDirection: 'column', justifyContent: "center" }}>
                                                    <p style={{ fontSize: '30px', fontWeight: 'bold' }}>{formatNumber(parseInt(formik.values.order_details[index].final_price))}</p>
                                                </div>
                                            </div>
                                        </Card>
                                    );
                                })}

                            </>
                        }
                        {selectTransactionDetailOption === TRANSACTION_DETAIL_OPTION.ADD_ITEM &&
                            <AddItem
                                formik={formik}
                                index={formik.values.order_details.length - 1}
                                selectTransactionDetailOption={selectTransactionDetailOption}
                                setSelectTransactionDetailOption={setSelectTransactionDetailOption}
                            />
                        }
                        {selectTransactionDetailOption === TRANSACTION_DETAIL_OPTION.EDIT_ITEM &&
                            <AddItem
                                formik={formik}
                                index={indexEditItem}
                                selectTransactionDetailOption={selectTransactionDetailOption}
                                setSelectTransactionDetailOption={setSelectTransactionDetailOption}
                                initialData={initialData}
                            />
                        }

                    </div>
                }
                {selectRadio === TRANSACTION_RADIO_OPTION.PAYMENT &&
                    <>
                        <Form onSubmit={formik.handleSubmit}>

                            <Form.Item validateStatus={formik.errors.payment_status && 'error'}>
                                <div><label>Payment Status : </label></div>
                                <Radio.Group
                                    buttonStyle="solid"
                                    style={{ marginBottom: "20px", width: "100%" }}
                                    value={selectPaymentStatus}
                                    onChange={(e) => {
                                        formik.setFieldValue("payment.status", e.target.value);
                                        setSelectPaymentStatus(e.target.value);
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', width: "100%" }}>
                                        {optionRadioPaymentStatus.map((item) => {
                                            return (
                                                <Radio.Button value={item.value} style={{ width: "100%" }}>
                                                    {item.label}
                                                </Radio.Button>
                                            );
                                        })}
                                    </div>
                                </Radio.Group>
                            </Form.Item>
                            {selectPaymentStatus === TRANSACTION_PAYMENT_OPTION.PAID &&
                                <>
                                    <Form.Item validateStatus={formik.errors.payment?.method && 'error'}>
                                        <div><label>Payment Method : </label></div>
                                        <Radio.Group
                                            buttonStyle="solid"
                                            style={{ marginBottom: "20px", width: "100%" }}
                                            value={formik.values.payment.method}
                                            onChange={(e) => {
                                                formik.setFieldValue("payment.method", e.target.value);
                                            }}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', width: "100%" }}>
                                                {optionRadioPaymentMethod.map((item) => {
                                                    return (
                                                        <Radio.Button value={item.value} style={{ width: "100%" }}>
                                                            {item.label}
                                                        </Radio.Button>
                                                    );
                                                })}
                                            </div>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item validateStatus={formik.errors.payment?.date && 'error'}>
                                        <div><label>Payment Date : </label></div>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format={"dddd, DD MMMM YYYY"}
                                            value={formik.values.payment?.date ? moment(formik.values.payment?.date) : null}
                                            onChange={(value) => { formik.setFieldValue("payment.date", value ? value.toISOString() : null) }}
                                        />
                                    </Form.Item>

                                    {formik.values.payment?.method !== 'cash' && (
                                        <>
                                            <div><label>Upload Bukti Bayar : </label></div>
                                            <UploadPhotoEvidence formik={formik} />
                                        </>
                                    )}

                                    <Form.Item validateStatus={formik.errors.quantity && 'error'}>
                                        <div><label>Total Bayar : </label></div>
                                        <Input
                                            type='text'
                                            value={formatCurrency(parseInt(formik.values.payment?.money) || 0)}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                // Mengabaikan karakter selain digit (contoh: comma atau titik)
                                                const numericValue = parseFloat(value.replace(/[^\d]/g, ''));

                                                // Menangani nilai awal NaN
                                                const newValue = isNaN(numericValue) ? 0 : numericValue;

                                                formik.setFieldValue("payment.money", newValue);
                                                formik.setFieldValue("payment.change", parseInt(newValue) - (parseInt(formik.values.payment.total_price) + parseInt(formik.values.payment.total_discount)));
                                            }}
                                        />

                                    </Form.Item>
                                </>
                            }
                            {/* Total Harga dan diskon */}
                            <Card style={{ width: "100%", textAlign: 'center', marginBottom: "20px" }}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span style={{ fontWeight: 'bold' }}>Sub Total:</span>
                                            <span>{formatCurrency(parseInt(formik.values.payment.total_price))}</span>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span style={{ fontWeight: 'bold' }}>Diskon:</span>
                                            <span>{formatCurrency(parseInt(formik.values.payment.total_discount))}</span>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span style={{ fontWeight: 'bold' }}>Grand Total:</span>
                                            <span>{formatCurrency(parseInt(formik.values.payment.final_price))}</span>
                                        </div>
                                    </Col>
                                    {selectPaymentStatus === TRANSACTION_PAYMENT_OPTION.PAID &&
                                        <Col span={24}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span style={{ fontWeight: 'bold' }}>Kembalian:</span>
                                                <span> {formatCurrency(parseInt(formik.values?.payment?.change || 0))} </span>
                                            </div>
                                        </Col>
                                    }
                                    {/* Tambahkan data atau informasi lainnya di sini */}
                                </Row>
                            </Card>
                        </Form>
                        <div>
                            <Invoice open={openModalInvoice} setOpen={setOpenModalInvoice} formik={formik} />
                            {formik.values?.payment?.code && <>
                                <Button type="dash" onClick={() => setOpenModalInvoice(true)}>Download Invoice</Button>
                                <Button className='ml-3' type="dash" onClick={async () => PrintInvoice(formik.values)}>Cetak Invoice</Button>
                                <Button className='ml-3' type="dash" onClick={async () => PrintChecklist(formik.values)}>Cetak Checklist</Button>
                            </>}

                        </div>
                        <div className='mt-5' style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button onClick={() => setOpen(false)}>Batal</Button>
                            <Button
                                loading={formik.isSubmitting}
                                className="items-center flex justify-center text-white bg-blue-400" htmlType="submit" style={{ marginLeft: '8px' }} onClick={() => formik.handleSubmit()}>
                                {initialData ? "Update Transaction" : "Tambah Transaction"}
                            </Button>
                        </div>
                    </>

                }

            </Modal>
            <ModalAddCustomer open={openModalAddCustomer} setOpen={setOpenModalAddCustomer} />

        </div >
    );
};

export default ModalAddTransaction;
