import { UserDataModel } from "./userData";

export const validateUpdateOrderData = (data) => {
    const requiredFields = [
      { field: 'entry_date', message: 'Entry Date kosong' },
      { field: 'customer_id', message: 'Customer ID kosong' },
      { field: 'quantity', message: 'Quantity kosong' },
      { field: 'order_type', message: 'Order Type kosong' },
      { field: 'payment.status', message: 'Payment Status kosong' },
      { field: 'payment.method', message: 'Payment Method kosong' },
      { field: 'payment.total_process_time_cost', message: 'Total Process Time Cost kosong' },
      { field: 'payment.total_discount', message: 'Total Discount kosong' },
      { field: 'payment.total_price', message: 'Total Price kosong' },
      { field: 'payment.final_price', message: 'Final Price kosong' },
    ];
  
    for (const field of requiredFields) {
      const value = field.field.split('.').reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, data);
      if (!value && value !== 0) {
        throw new Error(field.message);
      }
    }
  
    // Validasi order_details
    if (!data.order_details || !Array.isArray(data.order_details) || data.order_details.length === 0) {
      throw new Error('Order Details kosong');
    }
  
    data.order_details.forEach((orderDetail, index) => {
      const orderDetailRequiredFields = [
        { field: 'employee_id', message: `Employee ID kosong di order detail ke-${index + 1}` },
        { field: 'treatment_id', message: `Treatment ID kosong di order detail ke-${index + 1}` },
        { field: 'item_id', message: `Item ID kosong di order detail ke-${index + 1}` },
        { field: 'process_time', message: `Process Time kosong di order detail ke-${index + 1}` },
        { field: 'due_date', message: `Due Date kosong di order detail ke-${index + 1}` },
        { field: 'price', message: `Price kosong di order detail ke-${index + 1}` },
        { field: 'final_price', message: `Final Price kosong di order detail ke-${index + 1}` },
        { field: 'status', message: `Status kosong di order detail ke-${index + 1}` },
      ];
  
      for (const field of orderDetailRequiredFields) {
        const value = field.field.split('.').reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, orderDetail);
        if (!value && value !== 0) {
          throw new Error(field.message);
        }
      }
    });
  };
export const validatePostOrderData = (data) => {
    const requiredFields = [
        { field: 'customer_id', message: 'Customer kosong' },
      { field: 'entry_date', message: 'Entry Date kosong' },
      { field: 'quantity', message: 'Quantity kosong' },
      { field: 'order_type', message: 'Order Type kosong' },
      { field: 'payment.status', message: 'Payment Status kosong' },
      { field: 'payment.method', message: 'Payment Method kosong' },
      { field: 'payment.total_process_time_cost', message: 'Total Process Time Cost kosong' },
      { field: 'payment.total_discount', message: 'Total Discount kosong' },
      { field: 'payment.total_price', message: 'Total Price kosong' },
      { field: 'payment.final_price', message: 'Final Price kosong' },
    ];
  
    for (const field of requiredFields) {
      const value = field.field.split('.').reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, data);
      if (!value && value !== 0) {
        throw new Error(field.message);
      }
    }
  
    // Validasi order_details
    if (!data.order_details || !Array.isArray(data.order_details) || data.order_details.length === 0) {
      throw new Error('Order Details kosong');
    }
  
    data.order_details.forEach((orderDetail, index) => {
      const orderDetailRequiredFields = [
        { field: 'treatment_id', message: `Treatment ID kosong di order detail ke-${index + 1}` },
        { field: 'process_time', message: `Process Time kosong di order detail ke-${index + 1}` },
        { field: 'price', message: `Price kosong di order detail ke-${index + 1}` },
        { field: 'final_price', message: `Final Price kosong di order detail ke-${index + 1}` },
      ];
  
      for (const field of orderDetailRequiredFields) {
        const value = field.field.split('.').reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, orderDetail);
        if (!value && value !== 0) {
          throw new Error(field.message);
        }
      }
    });
  };
  

export const orderDataDetailModel = {
    id: "",
    employee_name: "",
    washing_status: "",
    shoes: {
        name: "",
        brand: "",
        type: "",
        color: "",
        price: "",
    },
    treatment: {
        treatment_name: "",
        price: "",
    },
    status: "",
    shoes_photos: [
        {
            url_photo: ""
        }
    ],
    action: "",
};

export const orderDataModel = {
    id: "1",
    customer: UserDataModel,
    entry_date: "28/08/2021",
    due_date: "30/08/2021",
    quantity: "3",
    total_price: "100.000",
    payment_method: "cash",
    payment_status: "paid",
    action: "",
    order_details: [
        orderDataDetailModel
    ]
};

export const orderDataAllModel = [
    orderDataModel
];

export const PostDataTransactionModel = {
    id: '0',
    payment_id: '',
    payment: {
        id: '0',
        code: "",
        status: "paid",
        method: "",
        total_discount: "",
        total_price: "",
        date: null,
        money: "",
        change: "",
    },
    customer_id: '',
    branch_id: '1',
    entry_date: new Date(),
    quantity: '',
    createdAt: "",
    order_details: [
        // {
        //     id: '-1',
        //     order_id: '',
        //     employee_id: '',
        //     treatment_id: '',
        //     item_id: '',
        //     discount_id: '',
        //     due_date: "",
        //     price: '',
        //     final_price: '',
        //     status: '',
        //     description: '',
        //     shoes_photos: [
        //         // {
        //         //     id: '-1',
        //         //     order_detail_id: '0',
        //         //     url_photo: "",
        //         //     uid: '-1',
        //         //     name: '',
        //         //     status: '',
        //         //     url: '',
        //         // },
        //     ],
        // }
    ],
}

export const WashingStatusOption = [
    { label: 'Antrian', value: 'not_yet' },
    { label: 'Proses', value: 'on_progress' },
    { label: 'Pengeringan', value: 'pengeringan' },
    { label: 'Siap Diambil', value: 'siap' },
    { label: 'Selesai', value: 'done' },
    { label: 'Batal', value: 'cancelled' },
];

export const fileList = [
    {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
        uid: '-2',
        name: 'image.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
        uid: '-3',
        name: 'image.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
        uid: '-4',
        name: 'image.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
        uid: '-xxx',
        percent: 50,
        name: 'image.png',
        status: 'uploading',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
        uid: '-5',
        name: 'image.png',
        status: 'error',
    },
];

export const FormikDataTransactionToPostRequest = (formikValues) => {
    const { payment, order_type, customer_id, entry_date, quantity, order_details } = formikValues;

    const data = {
        id: '0',
        payment_id: '0',
        payment: {
            id: '0',
            code: payment.code,
            status: payment.status,
            method: payment.method || 'unpaid',
            total_process_time_cost: parseFloat(payment.total_process_time_cost),
            total_discount: parseFloat(payment.total_discount),
            total_price: parseFloat(payment.total_price),
            final_price: parseFloat(payment.final_price),
            date: payment.date ? payment.date : null,
            money: parseFloat(payment.money),
            change: parseFloat(payment.change),
            evidence_photo: payment?.evidence_photo ? payment?.evidence_photo[0]?.url : null,
        },
        customer_id,
        branch_id: '1',
        entry_date,
        quantity,
        order_type,
        order_details: order_details
            .filter(item => item.id !== '-1')
            .map(item => ({
                id: item.id,
                order_id: item.order_id,
                employee_id: item.employee_id === '' ? null : item.employee_id,
                treatment_id: item.treatment_id,
                item_id: item.item_id === '' ? null : item.item_id,
                discount_id: item.discount_id === '' ? null : item.discount_id,
                process_time: item.process_time,
                process_time_cost: parseFloat(item.process_time_cost),
                due_date: item.due_date === '' ? null : item.due_date,
                price: item.price,
                final_price: item.final_price,
                status: item.status,
                description: item.description,
                shoes_photos: item.shoes_photos
                    .filter(photo => photo.id !== '-1')
                    .map(photo => ({
                        id: photo.id,
                        order_detail_id: photo.order_detail_id,
                        url_photo: photo.url_photo,
                    })),
            })),
    };

    const responseData = {
        isError: false,
        errorMessages: [],
        data: data
    }

    // Validasi data
    try {
        validatePostOrderData(data);
    } catch (error) {
        responseData.isError = true;
        responseData.errorMessages.push(error.message);
    }
  
    return responseData;
};

export const InitialDataToFormikDataTransaction = (data) => {
    const { payment, order_type, customer, customer_id, entry_date, quantity, order_details, createdAt } = data;

    const formikData = {
        id: data.id,
        payment: {
            id: payment?.id,
            code: payment?.code,
            status: payment?.status,
            method: payment?.method || 'unpaid',
            total_process_time_cost: payment?.total_process_time_cost,
            total_discount: payment?.total_discount,
            total_price: payment?.total_price,
            final_price: payment?.final_price,
            date: payment?.date,
            money: payment?.money,
            change: payment?.change,
            evidence_photo: payment?.evidence_photo,
        },
        payment_id: payment?.id,
        customer_id,
        entry_date,
        quantity,
        customer,
        order_type,
        createdAt,

        order_details: order_details?.map(item => ({
            id: item.id,
            order_id: item.order_id,
            employee_id: item.employee_id,
            treatment_id: item.treatment_id,
            item_id: item.item_id,
            discount_id: item.discount_id === '' ? null : item.discount_id,
            process_time: item.process_time,
            process_time_cost: item.process_time_cost,
            due_date: item.due_date,
            price: item.price,
            final_price: item.final_price,
            status: item.status,
            description: item.description,
            treatment: item.treatment,
            item: item.item,
            discount: item.discount,
            shoes_photos: item.shoes_photos?.map(photo => ({
                id: photo.id,
                order_detail_id: photo.order_detail_id,
                url_photo: photo.url_photo,
            })),
        })),
    };

    return formikData;
}

export const FormikDataTransactionToUpdateRequest = (formikValues) => {
    const { id, order_type, payment, customer_id, entry_date, quantity, order_details } = formikValues;
  
    const data = {
      id,
      payment_id: payment.id,
      payment: {
        id: payment.id,
        code: payment.code,
        status: payment.status,
        method: payment?.method || 'unpaid',
        total_process_time_cost: parseFloat(payment.total_process_time_cost),
        total_discount: parseFloat(payment.total_discount),
        total_price: parseFloat(payment.total_price),
        final_price: parseFloat(payment.final_price),
        date: payment.date ? payment.date : null,
        money: parseFloat(payment.money),
        change: parseFloat(payment.change),
        evidence_photo: payment?.evidence_photo ? payment?.evidence_photo[0]?.url : null,
      },
      customer_id,
      branch_id: '1',
      entry_date,
      quantity,
      order_type,
      order_details: order_details
        .filter(item => item.id !== '-1')
        .map(item => ({
          id: item.id,
          order_id: item.order_id,
          employee_id: item.employee_id,
          treatment_id: item.treatment_id,
          item_id: item.item_id,
          discount_id: item.discount_id === '' ? null : item.discount_id,
          process_time: item.process_time,
          process_time_cost: parseFloat(item.process_time_cost),
          due_date: item.due_date,
          price: item.price,
          final_price: item.final_price,
          status: item.status,
          description: item.description,
          shoes_photos: item.shoes_photos
            .filter(photo => photo.id !== '-1')
            .map(photo => ({
              id: photo.id,
              order_detail_id: photo.order_detail_id,
              url_photo: photo.url_photo,
            })),
        })),
    };

    const responseData = {
        isError: false,
        errorMessages: [],
        data: data
    }
  

    // Validasi data
    try {
        validateUpdateOrderData(data);
    } catch (error) {
        responseData.isError = true;
        responseData.errorMessages.push(error.message);
    }
  
    return responseData;
  };


export const PaymentMethodDisplay = (payment_method) => {
    switch (payment_method) {
        case 'cash':
            return 'Cash';
        case 'bank_transfer':
            return 'Transfer Bank';
        case 'qris':
            return 'QRIS';
        default:
            return 'Unpaid';
    }
}

export const PaymentStatusDisplay = (payment_status) => {
    switch (payment_status) {
        case 'paid':
            return 'Paid';
        case 'unpaid':
            return 'Unpaid';
        default:
            return '';
    }
}

export const WashingStatusDisplay = (washing_status) => {
    switch (washing_status) {
        case 'not_yet':
            return 'Antrian';
        case 'on_progress':
            return 'Proses';
        case 'pengeringan':
            return 'Pengeringan';
        case 'siap':
            return 'Siap Diambil/Dikirim';
        case 'delivered':
            return 'Pengantaran';
        case 'done':
            return 'Selesai';
        default:
            return '';
    }
}



  

