import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { API_URL, fetchData, postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { ConvertStringToArray } from '../../helper/convertStringToArray';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputCustom from '../../components/InputCustom';
import SelectCustom from '../../components/SelectCustom';
import ModalAddCustomer from '../component/ModalAddCustomer';
import ModalChooseProduct from './ModalChooseProduct';
import { formatCurrency } from '../../helper/formatCurrency';
import { calculateDiscountedPrice } from '../../helper/Calculation';
import ModalHandlePayment from './ModalHandlePayment';
import dayjs from 'dayjs';
import { PostMappingProductTransaction, UpdateMappingProductTransaction } from './helper';

const { Option } = Select;

const ModalAddProductTransaction = ({ open, setOpen, initialData, listData, trigerFetchData, setTrigerFetchData }) => {
    const [dataCustomerOption, setDataCustomerOption] = useState([]);
    const [openModalAddCustomer, setOpenModalAddCustomer] = useState(false);
    const [openModalChooseProduct, setOpenModalChooseProduct] = useState(false);
    const [discountDataOption, setDiscountDataOption] = useState([]);
    const [openModalHandlePayment, setOpenModalHandlePayment] = useState(false);

    const formik = useFormik({
        initialValues: {
            customer_id: '',
            customer: {
                id: '',
                name: '',
                phone: '',
                address: '',
            },
            branch_id: 1,
            payment_id: '',
            payment: {
                id: '',
                code: '',
                status: 'paid',
                method: 'cash',
                total_process_time_cost: 0,
                total_discount: '',
                total_price: '',
                final_price: '',
                money: '',
                date: Date.now(),
                change: 0,
                evidence_photo: '',
            },
            discount_id: '',
            discount: {
                id: '',
                code: '',
                discount: '',
                description: '',
            },
            date: '',
            total_price: '',
            order_product_details: [
                // {
                //     id: 0,
                //     order_product_id: '',
                //     product_id: '',
                //     product: {
                //         id: '',
                //         name: '',
                //         category: '',
                //         description: '',
                //         price: '',
                //         stock: '',
                //         images: '',
                //         sold: '',
                //     },
                //     discount_id: '',
                //     discount: {
                //         id: '',
                //         code: '',
                //         discount: '',
                //         description: '',
                //     },
                //     quantity: '',
                //     total_price_per_category: '',
                //     date: '',
                // }
            ]
        },
        validationSchema: Yup.object({
            customer_id: Yup.string().required('Customer is required'),
            branch_id: Yup.string().required('Branch is required'),
            discount_id: Yup.string().required('Discount is required'),
            payment: Yup.object().shape({
                status: Yup.string().required('Payment Status is required'),
                method: Yup.string().required('Payment Method is required'),
                total_price: Yup.number().required('Total Price is required'),
                final_price: Yup.number().required('Final Price is required'),
            }),
            date: Yup.string().required('Date is required'),
            total_price: Yup.number().required('Total Price is required'),
            order_product_details: Yup.array().of(
                Yup.object().shape({
                    product_id: Yup.string().required('Product is required'),
                    quantity: Yup.number().required('Quantity is required'),
                    total_price_per_category: Yup.number().required('Total Price is required'),
                    date: Yup.string().required('Date is required'),
                })
            ),
        }),
        onSubmit: values => {
            console.log("MASUKK")

            if (initialData) {
                const values = UpdateMappingProductTransaction(formik.values);
                console.log("valuesData", values);
                putData(`/order-products/${initialData.id}`, values)
                    .then((res) => {
                        toast.success('Data berhasil diupdate');
                        setTrigerFetchData(trigerFetchData + 1);
                        formik.resetForm();
                        setOpen(false);
                        setOpenModalHandlePayment(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error('Data gagal diupdate');
                    })
            } else {
                const values = PostMappingProductTransaction(formik.values);
                postData('/order-products', values)
                    .then((res) => {
                        toast.success('Data berhasil ditambahkan');
                        formik.resetForm();
                        setOpen(false);
                        setTrigerFetchData(trigerFetchData + 1);
                        setOpenModalHandlePayment(false);
                    }).catch((err) => {
                        console.log(err);
                        toast.error('Data gagal ditambahkan');
                    }
                    )
            }
        }
    })

    useEffect(() => {
        if (initialData) {
            console.log("initial Data", initialData);
            formik.setValues(initialData);
        }
        formik.setFieldValue('date', Date.now());
        fetchData("/customers")
            .then((res) => {
                const dataCustomerList = res.data.data;
                const dataCustomerOptionTemp = [];
                dataCustomerList.map((item) => {
                    dataCustomerOptionTemp.push({
                        id: item.id,
                        name: item.name,
                        phone: item.phone,
                        ...item,
                    });
                });
                setDataCustomerOption(dataCustomerOptionTemp);
            })
            .catch((err) => {
                console.log("ERROR");
            });
        fetchData('/discounts')
            .then((response) => {
                const data = response.data.data;
                const options = data.map((item) => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                }));
                setDiscountDataOption(options);
            })
            .catch((error) => {
                console.log(error);
            });

    }, [initialData, listData]);

    const chooseProduct = () => {
        console.log('Choose Product');
        setOpenModalChooseProduct(true);
    }


    useEffect(() => {
        console.log(formik.values);

        // set total price
        const totalPrice = formik.values.order_product_details.reduce((acc, item) => acc + item.total_price_per_category, 0);
        const DiscountData = formik.values.discount;

        const calculate = calculateDiscountedPrice(totalPrice, DiscountData);

        formik.setFieldValue('total_price', totalPrice);
        formik.setFieldValue('payment.total_price', totalPrice);
        formik.setFieldValue('payment.total_discount', calculate.discount_nominal);
        formik.setFieldValue('payment.final_price', calculate.final_price);
    }, [formik.values.order_product_details, formik.values.discount]);

    const handlePayment = () => {
        console.log('Handle Payment');
        setOpenModalHandlePayment(true);
    }

    useEffect(() => {
        console.log(formik.values);
        console.log(formik.errors);
    }, [formik.values]);

    return (
        <div>
            <Modal
                title="Add ProductTransaction"
                open={open}
                onCancel={() => setOpen(false)}
                onOk={() => setOpen(false)}
                footer={null}
            >
                <div className='flex flex-col gap-4 w-full'>
                    <p className='text-md font-bold'>Data Pelanggan </p>
                    <div className='flex flex-col gap-1 items-start'>
                        <label>Customer : </label>
                        <Select
                            showSearch
                            size='large'
                            style={{ width: '100%' }}
                            placeholder="Select a customer"
                            optionFilterProp="children"
                            value={formik.values.customer_id}
                            onChange={(value, option) => {
                                formik.setFieldValue("customer_id", value)
                                formik.setFieldValue("customer", option.item)
                            }}
                        >
                            {dataCustomerOption.map((item) => {
                                return (
                                    <Option item={item} value={item.id}>{item.name} - {item.phone}</Option>
                                );
                            })}
                        </Select>
                        {/* <ErrorMessage name="customer_id" component="div" className="error-message" /> */}
                        <Button type="link" onClick={() => setOpenModalAddCustomer(true)}>
                            New Customer
                        </Button>
                    </div>

                    <div className='w-full'>
                        <p className='text-md font-bold mb-2'>Keranjang Belanja </p>

                        <div className='flex flex-col gap-2'>

                            {formik.values.order_product_details.map((productDetail, index) => (
                                <div key={index} className='rounded-lg p-2 border-2 border-blue-200 flex gap-2 justify-between items-center'>
                                    <div className='flex gap-2 items-center'>
                                        <img src={`${API_URL}/${productDetail.product.images}`} alt='product' className='w-14 h-14 object-cover' />
                                        <div>
                                            <p className='text-sm font-bold'>{productDetail.product.name}</p>
                                            <p className='text-sm'>x{productDetail.quantity}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className='text-sm font-bold'>{formatCurrency(productDetail.total_price_per_category)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <Button
                        className='bg-blue-500 text-white w-full'
                        onClick={() => chooseProduct()}
                    >
                        Pilih Produk
                    </Button>

                    <div>
                        <p className='text-md font-bold'>Tambahan</p>
                        <div>
                            <p>Pilih Diskon</p>
                            <Select
                                showSearch
                                size='large'
                                style={{ width: '100%' }}
                                placeholder="Select a discount"
                                optionFilterProp="children"
                                value={formik.values.discount_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`discount_id`, value)
                                    formik.setFieldValue(`discount`, option.item)
                                }}
                            >
                                {discountDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <div className='w-full rounded-lg p-2 bg-gray-100 flex gap-2 justify-between items-center'>
                        <div className='flex flex-col gap-3 w-full'>
                            <div className='flex justify-between items-center w-full '>
                                <p>Total Transaksi</p>
                                <p>{formatCurrency(formik.values.payment.total_price)}</p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <p>Total Discount</p>
                                <p>- {formatCurrency(parseInt(formik.values.payment.total_discount || 0))}</p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <p>Harga Akhir</p>
                                <p>{formatCurrency(formik.values.payment.final_price)}</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <Button
                                className='bg-blue-500 text-white w-full'
                                onClick={() => handlePayment()}
                            >
                                Pembayaran
                            </Button>
                        </div>
                    </div>


                </div>

            </Modal>
            <ModalAddCustomer open={openModalAddCustomer} setOpen={setOpenModalAddCustomer} />
            <ModalChooseProduct open={openModalChooseProduct} setOpen={setOpenModalChooseProduct} formik={formik} />
            <ModalHandlePayment open={openModalHandlePayment} setOpen={setOpenModalHandlePayment} formik={formik} />
        </div>
    );
};

export default ModalAddProductTransaction;
