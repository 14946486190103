import Expense from "./Expense";
import Order from "./Order";
import RowBox from "./RowBox";
import Income from "./Income";
import Revenue from "./Revenue";
import { useEffect, useState } from "react";
import { fetchData } from "../../../helper/api";



function Index(props) {
    const { startDate, endDate } = props;

    const [DataDashboard, setDataDashboard] = useState();

    useEffect(() => {
        fetchData('/genereteDashboardOverview?startDate=' + startDate + '&endDate=' + endDate)
            .then((res) => {
                console.log(res);
                const data = res.data.data;
                setDataDashboard(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [startDate, endDate]);

    return (
        <div>
            <RowBox data={DataDashboard} />
            <div className="grid grid-cols-2 gap-2">
                <div className="max-md:col-span-2">
                    <Order data={DataDashboard} />
                </div>
                <div className="max-md:col-span-2">
                    <Revenue data={DataDashboard} />
                </div>
                <div className="max-md:col-span-2">
                    <Income data={DataDashboard} />
                </div>
                <div className="max-md:col-span-2">
                    <Expense data={DataDashboard} />
                </div>
            </div>
        </div>
    );
}

export default Index;