export const formatCurrency = (value) => {
    // Menggunakan metode toLocaleString() untuk memformat nilai menjadi format mata uang IDR
    return value.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}

export function formatNumber(value) {
        const suffixes = ["", "K", "M", "B", "T"];
        const tier = (Math.log10(value) / 3) | 0;
    
        if (tier === 0) return value.toString();
    
        const suffix = suffixes[tier];
        const scale = Math.pow(10, tier * 3);
    
        const scaledValue = value / scale;
    
        // Menghilangkan desimal jika angka setelah koma adalah nol
        const formattedValue = scaledValue % 1 === 0 ? scaledValue.toFixed(0) : scaledValue.toFixed(1);
    
        return formattedValue + suffix;
    }