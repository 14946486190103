
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Button,
  Empty,
  Popover,
  Pagination,
  Input,
  Typography,
} from "antd";
import { IoIosAddCircle } from "react-icons/io";
import { columnsSalesOrder, orderDataToDataSourcesSalesOrder, salesOrderData } from "./helper";
import { useEffect, useState } from "react";
import { orderDataAllModel } from "../../models/orderData";
import { deleteData, fetchData } from "../../helper/api";
import ModalAddTransaction from "./ModalAddTransaction";
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateRangePicker } from "react-date-range";
import { addDays, set } from 'date-fns';
import { useQuery } from "../../helper/useQuery";
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import { FaMoneyBillWave, FaCartArrowDown } from "react-icons/fa";
import { FaPersonCirclePlus } from "react-icons/fa6";
import { GrServices } from "react-icons/gr";
import { TbShoe } from "react-icons/tb";
import { formatCurrency } from "../../helper/formatCurrency";
import ExportExcel from "./Excel/ExportExcel";
import Cashier from "./Cashier";

const { Search } = Input;
const { Title, Text } = Typography;


const SalesOrder = () => {
  // data order
  const [dataOrder, setDataOrder] = useState(salesOrderData.id === 0 ? [] : salesOrderData);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [statisticData, setStatisticData] = useState();
  const [userData, setUserData] = useState({});

  const [triggerFetchOrder, setTriggerFetchOrder] = useState(false);

  const location = useLocation();
  const history = useHistory();

  // Pagination & Filter Date
  const query = useQuery();
  const page = query.get('page') || 1;
  const pageSize = query.get('pageSize') || 1000;
  const startDate = query.get('startDate') || moment().subtract(5, 'days').format('YYYY-MM-DD');
  const endDate = query.get('endDate') || moment().format('YYYY-MM-DD');

  // search
  const [searchValue, setSearchValue] = useState('');

  // PAGINATION
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 1000,
  });

  const [SelectedFilter, setSelectedFilter] = useState('entry_date');
  const optionFilter = [
    { label: 'Entry Date', value: 'entry_date' },
    { label: 'Due Date', value: 'due_date' },
    { label: 'Payment Date', value: 'payment_date' },
  ];

  const handleFilter = (e) => {
    setSelectedFilter(e.target.value);
  }

  useEffect(() => {
    fetchData("/get-profile")
      .then((res) => {
        const data = res.data.data;
        setUserData(data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Option Cashier
  const optionTransaction = [
    { label: 'Cashier', value: 'cashier' },
    { label: 'List Transaction', value: 'list_transaction' },
  ];

  const [optionTransactionValue, setOptionTransactionValue] = useState('cashier');

  useEffect(() => {
    // Fetch data order dengan parameter dari URL
    const fetchDataWithParams = async () => {
      try {
        let url = `/orders?page=${page}&pageSize=${pageSize}&search=${encodeURIComponent(searchValue)}`;

        if (SelectedFilter === 'entry_date') {
          url += `&startDate=${startDate}&endDate=${endDate}`;
        } else if (SelectedFilter === 'due_date') {
          // Tambahkan rentang tanggal "Due Date" jika dipilih
          url += `&dueStartDate=${startDate}&dueEndDate=${endDate}`;
        } else if (SelectedFilter === 'payment_date') {
          // Tambahkan rentang tanggal "Payment Date" jika dipilih
          url += `&paymentStartDate=${startDate}&paymentEndDate=${endDate}`;
        } else {
          // Jika tidak ada filter yang dipilih, maka gunakan filter "Entry Date"
          url += `&startDate=${startDate}&endDate=${endDate}`;
        }

        // delete add update param
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add=\d+|&update=\d+/, "");
        history.push({
          ...location,
          search: newSearchLocation,
        });
        fetchData(url)
          .then((res) => {
            const data = res.data.data;
            const dataSources = orderDataToDataSourcesSalesOrder(data.orders);
            const statistics = data.statistics;
            setDataOrder(dataSources);
            setStatisticData(statistics);

            setPagination({
              ...pagination,
              totalItems: data.pagination.totalItems,
              totalPages: data.pagination.totalPages,
              currentPage: parseInt(data.pagination.currentPage),
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error("ERROR:", error);
      }
    };

    fetchDataWithParams();
  }, [page, pageSize, startDate, endDate, openModalAdd, triggerFetchOrder, location.search, searchValue, SelectedFilter]);

  const handleOpenModalAdd = () => {
    setOpenModalAdd(true);
  }

  useEffect(() => {
    // HANDLE DELETE TRANSACTION
    const deleteParam = query.get('delete');

    // Lakukan sesuatu dengan nilai parameter "delete"
    if (deleteParam) {
      deleteData(`/orders/${deleteParam}`)
        .then((res) => {
          console.log(res);
          toast.success('Delete Transaction Success');
          // Menghapus parameter "delete" dari URL
          history.push({
            pathname: location.pathname,
            search: `?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
          });
          setTriggerFetchOrder(!triggerFetchOrder);
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }, [location.search]);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const handleFilterTanggal = (item) => {
    setState([item.selection]);
    const startDateParam = moment(item.selection.startDate).format('YYYY-MM-DD');
    const endDateParam = moment(item.selection.endDate).format('YYYY-MM-DD');

    if (page !== 1) {
      history.push({
        pathname: location.pathname,
        search: `?page=1&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
      });
    } else {
      // Mengupdate parameter URL
      history.push({
        pathname: location.pathname,
        search: `?page=${page}&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
      });
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPagination({
      ...pagination,
      currentPage: page,
      pageSize: pageSize
    });

    history.push({
      pathname: location.pathname,
      search: `?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
    });
  };

  const handlePageSizeChange = (current, size) => {
    setPagination({
      ...pagination,
      currentPage: current,
      pageSize: size
    });
    history.push({
      pathname: location.pathname,
      search: `?page=${current}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    });
  }

  const onSearch = (value) => {
    setSearchValue(value);
  }


  const content = (
    <DateRangePicker
      onChange={handleFilterTanggal} // Menggunakan fungsi handleFilterTanggal yang telah dibuat
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
    />
  );

  const statistics = [
    {
      name: `Total Revenue (${statisticData?.count_transaction || 0})`,
      value: formatCurrency(parseInt(statisticData?.sum_revenue || 0)),
      icon: <FaMoneyBillWave />,
    },
    {
      name: `Total Paid (${statisticData?.payment_status?.paid || 0})`,
      value: formatCurrency(parseInt(statisticData?.paid_revenue || 0)),
      icon: <FaMoneyBillWave />,
    },
    {
      name: `Total Unpaid (${statisticData?.payment_status?.unpaid || 0})`,
      value: formatCurrency(parseInt(statisticData?.unpaid_revenue || 0)),
      icon: <FaMoneyBillWave />,
    },
    {
      name: "Total Orders",
      value: statisticData?.count_transaction,
      icon: <FaCartArrowDown />,
    },
    {
      name: "Total Services",
      value: statisticData?.count_services,
      icon: <GrServices />,
    },
    {
      name: "Total Items",
      value: statisticData?.quantity_items,
      icon: <TbShoe />,
    },
  ];

  return (
    <>
      <ModalAddTransaction open={openModalAdd} setOpen={setOpenModalAdd} />

      {/* <Radio.Group
        style={{ marginBottom: "20px" }}
        options={optionTransaction}
        onChange={(e) => setOptionTransactionValue(e.target.value)}
        value={optionTransactionValue}
        optionType="button"
        buttonStyle="solid"
      /> */}

      <>
        {userData?.role === "superadmin" && (
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            {statistics.map((c, index) => (
              <Col
                key={index}
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                className="mb-4"
              >
                <Card bordered={false} className="criclebox ">
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={18}>
                        <span>{c.name}</span>
                        <Title level={3}>
                          {c.value}
                        </Title>
                      </Col>
                      <Col xs={6}>
                        <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        )}

        <div className="tabled">
          <Radio.Group
            style={{ marginBottom: "20px" }}
            options={optionFilter}
            onChange={handleFilter}
            value={SelectedFilter}
            optionType="button"
            buttonStyle="solid"
          />
          <Row style={{ display: 'block' }}>
            <Col xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-4"
                title={
                  <div style={{ display: "flex", gap: "20px" }}>
                    <h4>Transaction</h4>
                    <Input
                      style={{ width: "200px", fontSize: "12px" }}
                      placeholder="Search Name / Phone / Invoice / Unpaid"
                      size="small"
                      onPressEnter={(e) => {
                        if (e.key === 'Enter') {
                          onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                        }
                      }}
                    />
                    {userData?.role === "superadmin" && (
                      <ExportExcel orders={dataOrder} />
                    )}
                  </div>
                }
                extra={
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Popover content={content} placement="leftTop" title="Filter Tanggal" trigger="click">
                      <Button>
                        {SelectedFilter === 'entry_date' ? 'Entry Date' : SelectedFilter === 'due_date' ? 'Due Date' : 'Payment Date'}:
                        {` ${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}`}
                      </Button>
                    </Popover>

                    <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                      <IoIosAddCircle style={{ fontSize: "20px" }} />
                      Add Transaction
                    </Button>
                  </div>
                }
              >
                <div style={{ padding: "20px", display: 'flex', justifyContent: "flex-end" }}>
                  <Pagination
                    current={pagination.currentPage}
                    total={pagination.totalItems}
                    pageSize={pagination.pageSize}
                    onChange={handlePageChange}
                    onShowSizeChange={handlePageSizeChange}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100', '500', '1000']}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  />
                </div>
                <div className="table-responsive">
                  <Table
                    columns={columnsSalesOrder}
                    dataSource={dataOrder}
                    pagination={false}
                    className="ant-border-space"
                    locale={{
                      emptyText: <Empty description="Data not found" />,
                    }}
                  />
                </div>

              </Card>
            </Col>
          </Row>
        </div>
      </>


    </>

  );
}

export default SalesOrder;
