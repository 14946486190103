import React, { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_URL } from '../../helper/api';

const UploadProduct = ({ formik }) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    const handleChange = async (info) => {
        console.log(info);
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Mendapatkan URL dari respons server
            const response = info.file.response;
            if (response && response.success && response.data && response.data[0]) {
                const url = response.data[0].url_photo;
                formik.setFieldValue('images', url);
                setImageUrl(url);
                setLoading(false);
                message.success('Upload successful');
            } else {
                setLoading(false);
                message.error('Upload failed');
            }
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    useEffect(() => {
        console.log("URL PHOTO", formik.values);
        if (formik.values.images) {
            setImageUrl(`${API_URL}/${formik.values.images}`);
        } else {
            setImageUrl(null);
        }
    }, [formik.values]);

    return (
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={`${API_URL}/uploads-product`}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
            {formik.values?.images || imageUrl ? (
                <img
                    className='h-full object-cover'
                    src={formik.values?.images ? `${API_URL}/${formik.values?.images}` : imageUrl}
                    alt="avatar"
                    style={{
                        width: '100%',
                    }}
                />
            ) : (
                uploadButton
            )}
        </Upload>
    );
};

export default UploadProduct;
