import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button, DatePicker } from 'antd';
import { fetchData, postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helper/formatCurrency';
import numeral from 'numeral';
import { useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import UploadPhoto from './UploadPhoto';
import { useFormik } from 'formik';
import moment from 'moment';
import { Dayjs } from 'dayjs';

const { Option } = Select;

const ModalAddExpense = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData, nameOption, categoryOption }) => {
    const location = useLocation();
    const history = useHistory();

    const [employee, setEmployee] = React.useState([]);

    const formik = useFormik({
        initialValues: {
            user_id: '',
            name: [],
            date: new Date(),
            type_account: '',
            amount: '',
            // category: [],
            // description: '',
            payment_method: '',
            receipt: '',
        },
        onSubmit: values => {
            const nameAsString = Array.isArray(values.name) ? values.name.join(', ') : values.name;
            // const categoryAsString = Array.isArray(values.category) ? values.category.join(', ') : values.category;

            const newData = {
                ...values,
                name: nameAsString,
                // category: categoryAsString,
                receipt: values?.receipt[0]?.url_photo ? values?.receipt[0]?.url_photo : '',
            }
            if (initialData) {
                putData(`/expenses/${initialData.id}`, newData)
                .then((response) => {
                        formik.resetForm();
                        toast.success('Expense updated successfully');
                        setOpen(false);
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                console.log("MASUK");
                postData('/expenses', newData)
                    .then((response) => {
                        formik.resetForm();
                        toast.success('Expense added successfully');
                        setOpen(false);
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    })

    const handleAddExpense = () => {
        if (initialData) {
        } else {
        }

    };

    useEffect(() => {
        if (initialData) {
            formik.setValues({
                user_id: initialData.user_id,
                name: initialData.name,
                date: initialData.date,
                type_account: initialData.type_account,
                amount: initialData.amount,
                // category: initialData.category,
                // description: initialData.description,
                payment_method: initialData.payment_method,
                receipt: initialData.receipt,
            });
        }
    }, [initialData, trigerFetchData]);

    useEffect(() => {
        fetchData(`/users`)
            .then((res) => {
                const data = res.data.data;
                setEmployee(
                    data.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            item: item
                        }
                    }
                    )
                );
            })
            .catch((err) => {
                console.log(err);
            })
    }, [trigerFetchData]);

    return (
        <Modal
            title={`${initialData ? 'Edit' : 'Add'} Expense`}
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form layout="vertical" onSubmit={formik.handleSubmit}>
                <Form.Item
                    label="Buyer"
                >
                    <Select
                        placeholder="Select buyer"
                        value={formik.values?.user_id || ''}
                        onChange={(e, option) => {
                            console.log(e);
                            formik.setValues({
                                ...formik.values,
                                user_id: e,
                                name: option.name,
                                user: option.item
                            });

                        }}
                    >
                        {employee.map((item) => {
                            return (
                                <Option value={item.id} item={item.item}>{item.name}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Name" >
                    <Select
                        mode='tags'
                        placeholder="name"
                        maxTagCount={1}
                        value={formik.values?.name}
                        onChange={(value) => {
                            formik.setFieldValue('name', value);
                        }}
                    >
                        {nameOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Date">
                    <DatePicker
                        name='date'
                        style={{ width: '100%' }}
                        format={"dddd, DD MMMM YYYY"}
                        onChange={(value) => formik.setFieldValue('date', value)}
                        value={formik.values?.date ? moment(formik.values?.date) : null}
                    />
                </Form.Item>

                <Form.Item label="Type Account">
                    <Select placeholder="Select type Account"
                        value={formik.values?.type_account}
                        onChange={(e) => {
                            formik.setFieldValue('type_account', e);
                        }}
                    >
                        <Option value="perlengkapan">Perlengkapan</Option>
                        <Option value="peralatan">Peralatan</Option>
                        <Option value="beban">Beban</Option>
                        <Option value="modal_bisnis">Modal Bisnis</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Amount" >
                    <Input
                        type='text'
                        value={formatCurrency(parseInt(formik.values?.amount || 0))}
                        onChange={(event) => {
                            const value = event.target.value;
                            // Mengabaikan karakter selain digit (contoh: comma atau titik)
                            const numericValue = parseFloat(value.replace(/[^\d]/g, ''));

                            // Menangani nilai awal NaN
                            const newValue = isNaN(numericValue) ? 0 : numericValue;

                            formik.setFieldValue("amount", newValue);
                        }}
                    />
                </Form.Item>

                {/* <Form.Item label="Category">
                    <Select
                        mode='tags'
                        placeholder="Category"
                        value={formik.values?.category}
                        onChange={(e) => {
                            formik.setFieldValue('category', e);
                        }}
                    >
                        {categoryOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>

                </Form.Item>
                <Form.Item label="Description" >
                    <Input
                        placeholder="Description"
                        value={formik.values?.description}
                        onChange={(e) => { formik.setFieldValue('description', e.target.value); }}
                    />
                </Form.Item> */}

                <Form.Item label="Payment Method" >
                    <Select placeholder="Select payment method"
                        value={formik.values?.payment_method}
                        onChange={(e) => {
                            formik.setFieldValue('payment_method', e);
                        }}
                    >
                        <Option value="cash">Cash</Option>
                        <Option value="bank_transfer">Transfer</Option>
                        <Option value="shopee">Shopee</Option>
                    </Select>
                </Form.Item>

                <label>Receipt</label>

                <UploadPhoto formik={formik} />

                <Button style={{ width: "100%" }} className="items-center flex justify-center text-white bg-blue-400" onClick={() => formik.handleSubmit()}>
                    {initialData ? 'Update' : 'Add'} Expense
                </Button>
            </Form>
        </Modal >
    );
};

export default ModalAddExpense;
