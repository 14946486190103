import React, { useEffect } from 'react';
import {
    Button,
    Modal
} from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import ModalSalesOrder from './ModalSalesOrder';
import { deleteData } from '../../helper/api';
import { useHistory, useLocation } from 'react-router-dom';
import ModalAddTransaction from './ModalAddTransaction';

function ActionSalesOrder(props) {
    const { record } = props
    const location = useLocation();
    const history = useHistory();

    const [open, setOpen] = React.useState(false)

    const [openModalDelete, setOpenModalDelete] = React.useState(false)
    const [openModalTransaction, setOpenModalTransaction] = React.useState(false)
    const [recordData, setRecordData] = React.useState(record)

    useEffect(() => {
        setRecordData(record)
    }, [record])

    const handleDeleteTransaction = () => {

        // Membuat query string dengan menambahkan parameter id
        const newDelete = `&delete=${record.id}`;

        // Memperbarui location dengan menambahkan parameter
        const newLocation = {
            ...location,
            search: location.search + newDelete,
        };

        // Menggunakan replace agar tidak menambahkan entri ke dalam history stack
        history.push(newLocation);
        setOpenModalDelete(false)
    }

    return (
        <div className='d-flex gap-2'>
            {/* MODAL TRANSACTION */}
            <ModalAddTransaction open={openModalTransaction} setOpen={setOpenModalTransaction} initialData={recordData} />

            {/* MODAL DELETE */}
            <Modal
                title="Delete"
                open={openModalDelete}
                onOk={() => handleDeleteTransaction()}
                onCancel={() => setOpenModalDelete(false)}
            >
                {/* ARE YOU SURE */}
                <h2>Are you sure want to delete this transaction {record.code}?</h2>
            </Modal>

            <div>
                <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => setOpenModalTransaction(true)}>
                    <MdEdit style={{ fontSize: "20px" }} />
                </Button>
                {/* <Button style={{ width: "50px" }} type="danger" className="item-center" onClick={() => setOpenModalDelete(true)}>
                    <MdDelete style={{ fontSize: "20px" }} />
                </Button> */}
            </div>

        </div>
    );
}

export default ActionSalesOrder;