import React, { useEffect } from 'react'
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { Button, Col, Row, Table, Modal } from 'antd';
import dayjs from 'dayjs';
import KatsikatLogo from '../../../assets/images/KatsikatLogo.png';
import { formatCurrency } from '../../../helper/formatCurrency';

export default function Invoice2({ open, setOpen, formik }) {
  const refPDF = React.useRef();
  const [DueDate, setDueDate] = React.useState('');
  const [ItemData, setItemData] = React.useState([]);
  const [ShoesPhotos, setShoesPhotos] = React.useState([]);

  const options = {
    // default is `save`
    method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.NONE,
      // default is 'A4'
      format: 'A4',
      // default is 'portrait'
      orientation: 'portrait',
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'image/jpeg',
      ratio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true
      }
    },
  };
  // const shoePhotos = [
  //   'https://th.bing.com/th/id/OIP.7SeMY3g9G_4NuDeEHBz8DgHaE3?rs=1&pid=ImgDetMain',
  //   'https://th.bing.com/th/id/OIP.7SeMY3g9G_4NuDeEHBz8DgHaE3?rs=1&pid=ImgDetMain',
  //   'https://th.bing.com/th/id/OIP.7SeMY3g9G_4NuDeEHBz8DgHaE3?rs=1&pid=ImgDetMain',
  //   'https://th.bing.com/th/id/OIP.7SeMY3g9G_4NuDeEHBz8DgHaE3?rs=1&pid=ImgDetMain',
  //   'https://th.bing.com/th/id/OIP.7SeMY3g9G_4NuDeEHBz8DgHaE3?rs=1&pid=ImgDetMain',
  //   // Tambahkan URL foto sepatu lainnya sesuai kebutuhan
  // ];


  React.useEffect(() => {

    const orderDetails = formik.values?.order_details;

    if (orderDetails && orderDetails.length > 0) {
      const dueDates = orderDetails.map((orderDetail) => orderDetail?.due_date);

      // Filter nilai yang valid dan bukan undefined
      const validDueDates = dueDates.filter((dueDate) => dueDate !== undefined && !isNaN(Date.parse(dueDate)));

      if (validDueDates.length > 0) {
        // Konversi string tanggal menjadi objek Date
        const dateObjects = validDueDates.map((dueDate) => new Date(dueDate));

        const maxDueDate = new Date(Math.max(...dateObjects));

        // Format menggunakan Day.js
        const formattedDueDate = dayjs(maxDueDate).format('DD MMMM YYYY');

        setDueDate(formattedDueDate);
      } else {
        console.error("Semua due date tidak valid atau kosong");
      }
    } else {
      console.error("Tidak ada order_details yang ditemukan");
    }

    const itemData = formik.values?.order_details
      .filter(orderDetail => orderDetail?.id !== '-1') // Filter data dengan id bukan -1
      .map((orderDetail, index) => ({
        key: index,
        item: (
          <>
            {orderDetail?.item?.brand} - {orderDetail?.item?.name}
            <br />
            <div>
            <p className='font-bold' style={{ fontSize:"10px", color:'black' }}>Catatan: </p>
            <p style={{ fontSize:"10px", color:'black' }}>{orderDetail?.description}</p>
            </div>
          </>
        ),
        treatment: orderDetail?.treatment?.name,
        price: formatCurrency(parseInt(orderDetail?.price) + parseInt(orderDetail?.process_time_cost || 0)),
      }));

    setItemData(itemData);


    // get shoes photos from order details.shoes photos, and get the url and put it in array
    let newShoesPhotos = [];
    formik.values?.order_details?.map((orderDetail) => orderDetail?.shoes_photos?.map((shoesPhoto) => {
      if (shoesPhoto?.id !== '-1') {
        setShoesPhotos(prevState => [...prevState, shoesPhoto?.url_photo]);
      }
    }));

  }, [])

  return (
    <>
      <Modal
        title="Invoice"
        open={open}
        width={900}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <div ref={refPDF} style={{ maxWidth: '850px', width: "795px", margin: '0 auto', backgroundColor: "white" }}>
          <div style={{ minHeight: "720px" }}>
            <div style={{ backgroundColor: '#9CD2FF', height: "200px", borderRadius: '0 0 70px 70px' }}>
              <div style={{ paddingTop: '50px', display: 'flex', justifyContent: 'space-between' }}>
                {/* Group KATSIKAT.ID */}
                <div style={{ display: 'flex' }}>
                  <div style={{ backgroundColor: 'white', height: "60px", width: "15px", marginTop: '-8px' }}>
                  </div>
                  <div style={{ display: "flex" }}>
                    <img src={KatsikatLogo} alt="Katsikat Logo" style={{ width: '50px', height: '50px', marginLeft: '30px' }} />
                    <div style={{ color: "white", marginLeft: "10px" }}>
                      <label style={{ fontWeight: "bold", fontSize: '20px' }}>KATSIKAT.ID</label>
                      <p style={{ marginTop: "-8px" }}>Bersih Langkah Percaya Diri</p>
                    </div>
                  </div>
                </div>
                {/* Group INVOICE */}
                <div style={{ display: 'flex', gap: '30px' }}>
                  <div style={{ display: "flex", marginTop: '-8px' }}>
                    <div style={{ color: "white", marginLeft: "10px" }}>
                      <label style={{ fontWeight: "bold", fontSize: '40px' }}>INVOICE</label>
                    </div>
                  </div>
                  <div style={{ backgroundColor: 'white', height: "60px", width: "15px", marginTop: '-8px' }}>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: 'white', height: "auto", padding: '20px', borderRadius: '30px 30px 30px 30px', marginTop: "-70px", marginLeft: '20px', marginRight: '20px', }}>
              <div style={{ display: 'flex', justifyContent: "space-between", gap: '100px' }}>
                <div style={{ display: 'flex', justifyContent: "space-between", gap: '10px' }}>
                  <div>
                    <div style={{ backgroundColor: '#9CD2FF', height: "70px", width: "10px", marginTop: "30px" }}></div>
                  </div>
                  <div style={{ color: "black" }}>

                    <h5 style={{ fontSize: "14px", fontWeight: "bold" }}>INVOICE TO</h5>
                    <h3 className='font-bold text-lg'>{formik.values?.customer?.name}  </h3>
                    <div>{formik.values?.customer?.address}</div>
                    <div>{formik.values?.customer?.phone}</div>
                  </div>
                </div>
                <div style={{ color: "black" }}>
                  <h5 style={{ fontSize: "14px", fontWeight: "bold" }}>#{formik.values?.payment?.code}</h5>
                  <div style={{ display: 'flex', justifyContent: "flex-end", fontWeight: "bold", fontSize: "12px" }}>ENTRY DATE</div>
                  <div style={{ display: 'flex', justifyContent: "flex-end" }}>{dayjs(formik.values?.entry_date).format('DD MMMM YYYY')}</div>
                  <div style={{ display: 'flex', justifyContent: "flex-end", fontWeight: "bold", fontSize: "12px" }}>ESTIMATION DATE</div>
                  <div style={{ display: 'flex', justifyContent: "flex-end" }}>{dayjs(DueDate).format('DD MMMM YYYY')}</div>
                </div>
              </div>


              <Row style={{ marginTop: 48 }}>
                <Table
                  style={{ width: '100%' }}
                  dataSource={ItemData}
                  pagination={false}
                >
                  <Table.Column title="Items" dataIndex='item' />
                  <Table.Column title="Treatment" dataIndex='treatment' />
                  <Table.Column title="Price" dataIndex='price' />
                </Table>
              </Row>
            </div>
          </div>

          <div style={{ display: 'flex', padding: '40px', justifyContent: "space-between", gap: '100px', marginTop: "100px" }}>
            <div style={{ display: 'flex', justifyContent: "space-between", gap: '10px' }}>
              <div>
                <div style={{ backgroundColor: '#9CD2FF', height: "20px", width: "10px", marginTop: "3px" }}></div>
              </div>
              <div style={{ color: "black" }}>
                <h4>PAYMENT INFO</h4>
                <div>No. Account : 6705286217</div>
                <div>BCA (a.n Hadad Al Akbar)</div>
              </div>
            </div>
            <div style={{ color: "black", fontSize: "15px", marginRight: "50px" }}>
              <div style={{ display: 'flex', justifyContent: "space-between", gap: '50px' }}>
                <div style={{ display: 'flex', justifyContent: "flex-start", fontWeight: "bold" }}>SUB TOTAL</div>
                <div style={{ display: 'flex', justifyContent: "flex-end" }}>{formatCurrency(parseInt(formik.values?.payment?.total_price))}</div>
              </div>
              <div style={{ display: 'flex', justifyContent: "space-between", gap: '50px' }}>
                <div style={{ display: 'flex', justifyContent: "flex-start", fontWeight: "bold" }}>DISCOUNT</div>
                <div style={{ display: 'flex', justifyContent: "flex-end" }}>{formatCurrency(parseInt(formik.values?.payment?.total_discount))}</div>
              </div>
              <div style={{ display: 'flex', justifyContent: "space-between", gap: '50px', marginTop: "10px", fontSize: "17px" }}>
                <div style={{ backgroundColor: "#38B6FF", padding: '5px', color: 'white', display: 'flex', justifyContent: "flex-start", fontWeight: "bold" }}>GRAND TOTAL</div>
                <div style={{ backgroundColor: "#38B6FF", padding: '5px', color: 'white', display: 'flex', justifyContent: "flex-end", fontWeight: "bold" }}>{formatCurrency(parseInt(formik.values?.payment?.final_price))}</div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', padding: '40px', justifyContent: "space-between", gap: '100px', marginTop: "-60px" }}>
            <div style={{ display: 'flex', justifyContent: "space-between", gap: '10px' }}>
              <div>
                <div style={{ backgroundColor: '#9CD2FF', height: "20px", width: "10px", marginTop: "3px" }}></div>
              </div>
              <div style={{ color: "black" }}>
                <h4>CUSTOMER SERVICE</h4>
                <div>Whatsapp: +62 877‐9545‐2475</div>
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: '#9CD2FF', height: "200px" }}>
            {/* Konten di dalam kotak */}
          </div>


          {/* SHOES PHOTO */}
          <div style={{ minHeight: "510px" }}>

            <div style={{ backgroundColor: 'white', height: "auto", padding: '20px', borderRadius: '30px 30px 30px 30px', marginTop: "-70px", marginLeft: '20px', marginRight: '20px', }}>
              <div style={{ display: 'flex', justifyContent: "space-between", gap: '100px' }}>
                <div style={{ display: 'flex', justifyContent: "space-between", gap: '10px' }}>
                  <div>
                    <div style={{ backgroundColor: '#9CD2FF', height: "70px", width: "10px", marginTop: "0px" }}></div>
                  </div>
                  <div style={{ color: "black" }}>
                    <h1 className='font-bold text-2xl'>SHOES PHOTOS</h1>
                    <p style={{ fontSize: "15px", fontStyle: 'italic' }}>Initial Condition</p>
                  </div>
                </div>
              </div>

              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]} style={{ marginTop: 48 }}>
                {ShoesPhotos.map((photo, index) => (
                  <Col key={index} span={12}>
                    <div style={{ borderRadius: '20px' }}>
                      <img src={`${photo}`} alt={`Sepatu ${index + 1}`} style={{ width: '100%', borderRadius: '20px' }} />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <div style={{ backgroundColor: '#9CD2FF', height: "80px", display: 'flex', justifyContent: 'space-around', alignItems: 'center', color: 'white' }}>
            <p>@katsikat.id</p>
            <p style={{ fontSize: '15px', fontWeight: "bold" }}>Shoes Treatment</p>
            <p>katsikat@gmail.com</p>
          </div>

        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: '10px', gap: '10px' }}>
          <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => generatePDF(refPDF, options)}>Download Invoice</Button>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </div>
      </Modal>
    </>
  )
}
