import React, { useEffect } from 'react';
import { Radio, Input, Button, Form, Select, Tabs, Modal } from 'antd';
import { FaShoppingCart } from "react-icons/fa";
import { GiRunningShoe } from "react-icons/gi";
import { RiScales2Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { fetchData } from '../../../helper/api';
import { set } from 'date-fns';
import { FaBoxOpen } from "react-icons/fa6";
import { formatCurrency } from '../../../helper/formatCurrency';
import { DisplayProcessTime } from '../helper';
import ShoppingCart from './ShoppingCart';
import { useFormik } from 'formik';
import { OrderDataStateModel, OrderDetailStateModels, PriceDisplayVarianWithProcessTime, ProcessTimeCalculationDataLaundry, ProcessTimeCalculationDisplay } from './helper';

function Index(props) {
    const [laundryType, setLaundryType] = React.useState('satuan');
    const [dataCashier, setDataCashier] = React.useState([]);
    const [dataLaundrySatuan, setDataLaundrySatuan] = React.useState([]);
    const [dataLaundryKiloan, setDataLaundryKiloan] = React.useState([]);

    const [selectedTypeItem, setSelectedTypeItem] = React.useState();

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isModalOpenTime, setIsModalOpenTime] = React.useState(false);

    const [selectedVariants, setSelectedVariants] = React.useState([]); // isinya adalah service kayak DeepClean, OutsideClean
    const [selectedVariantsForTime, setSelectedVariantsForTime] = React.useState([]); // isinya adalah service kayak DeepClean, OutsideClean

    const [defaultProcessTime, setDefaultProcessTime] = React.useState();
    const [processingTimeData, setProcessingTimeData] = React.useState([]);



    // Untuk Modal Varian
    const showModal = (treatment) => {
        setSelectedVariants(treatment?.varian);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // Untuk Modal Time
    const showModalTime = (treatment) => {
        setSelectedVariantsForTime(treatment?.varian)
        setIsModalOpenTime(true);
    };
    const handleOkTime = () => {
        setIsModalOpenTime(false);
    };
    const handleCancelTime = () => {
        setIsModalOpenTime(false);
    };


    // FORMIK DATA
    const formik = useFormik({
        initialValues: {

        },
        onSubmit: values => {
            console.log(values);
        }
    })

    useEffect(() => {
        formik.setValues(OrderDataStateModel)
    }, [])


    // FUNGSI UNTUK MENAMBAHKAN DATA TREATMENT YANG DIPILI
    // KEDALAM FORMIK ORDER DETAILS
    const handleAddToCart = (treatment) => {
        console.log("ADD TO CART TREATMENT", treatment);

        const selectedVariant = treatment?.varian?.find(item => item.selected);

        const newOrderDetails = {
            ...OrderDetailStateModels,
            treatment_id: selectedVariant.id,
            treatment_data: {
                id: selectedVariant.id,
                name: selectedVariant?.name,
                price: selectedVariant?.price,
                type_item: dataLaundrySatuan?.find(item => item.active)?.item_name,
                type_service: treatment.treatment_name,
                description: selectedVariant?.description,
                laundry_type: laundryType,
                process_time_name: selectedVariant?.process_time_name,
                process_time_type_cost: selectedVariant?.process_time_type_cost,
                process_time_value_cost: selectedVariant?.process_time_value_cost,
                process_time_value_nominal_cost: ProcessTimeCalculationDataLaundry(selectedVariant),
                discount_nominal_for_item: 0,
                final_price_with_process_time: parseInt(selectedVariant?.price) + ProcessTimeCalculationDataLaundry(selectedVariant),
                final_price_with_process_time_and_discount: parseInt(selectedVariant?.price) + ProcessTimeCalculationDataLaundry(selectedVariant),
            }
        }

        formik.setValues({
            ...formik.values,
            order_details: [...formik.values.order_details, newOrderDetails]
        })
    }


    // FUNGSI UNTUK MENAMBAHKAN STATUS ACTIVE DAN PROCESS TIME PADA DATA PERTAMA
    const AddStatusActiveInFirstData = (data, processTimeData) => {
        const processTimeDataMod = processTimeData.find(item => parseInt(item?.value_cost) === 0);
        setDefaultProcessTime(processTimeDataMod);

        console.log("processTimeDataMod", processTimeDataMod);

        const newData = data.map((item, index) => {
            if (index === 0) {
                return {
                    ...item,
                    active: true,
                    treatment: item.treatment.map(treatment => ({
                        ...treatment,
                        varian: treatment.varian.map((variant, index) => ({
                            ...variant,
                            selected: index === 0 ? true : false,
                            process_time_name: processTimeDataMod?.name,
                            process_time_type_cost: processTimeDataMod?.type_cost,
                            process_time_value_cost: processTimeDataMod?.value_cost,
                        }))
                    }))
                }
            } else {
                return {
                    ...item,
                    active: false,
                    treatment: item.treatment.map(treatment => ({
                        ...treatment,
                        varian: treatment.varian.map((variant, index) => ({
                            ...variant,
                            selected: index === 0 ? true : false,
                            process_time_name: processTimeDataMod?.name,
                            process_time_type_cost: processTimeDataMod?.type_cost,
                            process_time_value_cost: processTimeDataMod?.value_cost,
                        }))
                    }))
                }
            }
        })
        return newData;
    }

    // FUNGSI UNTUK MENGUBAH PROCEESS TIME NAME, COST PADA DATA LAUNDRY
    const handleChangeProcessTime = (processTimeData, laundryData) => {

    }


    useEffect(() => {
        fetchData('/processing-times')
            .then(res => {
                const data = res.data.data;
                const dataModified = data.map(item => {
                    return {
                        ...item,
                        selected: parseInt(item.value_cost) === 0 ? true : false,
                    }
                })

                setProcessingTimeData(dataModified);

                // FETCH DATA CASHIER
                fetchData('/cashier-data')
                    .then(res => {
                        const data = res.data.data;
                        setDataCashier(data);
                        const laundrySatuan = data.find(item => item?.laundry_type === 'satuan');
                        setDataLaundrySatuan(AddStatusActiveInFirstData(laundrySatuan?.items_type, dataModified));
                        const laundryKiloan = data.find(item => item?.laundry_type === 'kiloan');
                        setDataLaundryKiloan(AddStatusActiveInFirstData(laundryKiloan?.items_type, dataModified));

                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
            .catch(err => {
                console.log(err);
            })

    }, [])

    useEffect(() => {
        console.log("processingTimeData", processingTimeData);
    }, [dataLaundrySatuan])





    // FUNGSI MENAMPILKAN ITEM YANG DIPILIH
    const handleSelectTypeItem = (item_name) => {
        // add variable active to the selected item in DataLaundry
        // item_name ini akan dicocokkan dengan dataLaundrySatuan atau dataLaundryKiloan, 
        // jika cocok maka akan ditambahkan variable status active

        if (laundryType === 'satuan') {
            const newDataLaundrySatuan = dataLaundrySatuan.map(item => {
                if (item.item_name === item_name) {
                    return {
                        ...item,
                        active: true
                    }
                } else {
                    return {
                        ...item,
                        active: false
                    }
                }
            })
            setDataLaundrySatuan(newDataLaundrySatuan);
        } else {
            const newDataLaundryKiloan = dataLaundryKiloan.map(item => {
                if (item.item_name === item_name) {
                    return {
                        ...item,
                        active: true
                    }
                } else {
                    return {
                        ...item,
                        active: false
                    }
                }
            })
            setDataLaundryKiloan(newDataLaundryKiloan);
        }
    }


    //  FUNGSI UNTUK MENAMPILKAN TREATMENT
    const ChildrenContent = () => {
        const CardTreatment = ({ treatment }) => {
            return (
                <>
                    <div className='col-span-2 border-2 bg-white shadow-md rounded-lg bg-white w-full'>
                        <div className='flex flex-col gap-1'>
                            <div>
                                <img src='https://static.vecteezy.com/system/resources/thumbnails/000/508/618/small/Ecommerce_1298.jpg'
                                    alt='shoes'
                                    className='w-full h-30 object-cover rounded-t-lg' />
                            </div>
                            <div className='flex flex-col p-4 gap-2'>
                                <div className='flex justify-between text-black font-bold items-center'>
                                    <p>{treatment.treatment_name}</p>
                                    <p>{treatment?.varian?.map(variant => {
                                        return variant.selected && PriceDisplayVarianWithProcessTime(variant)
                                    })}</p>
                                </div>
                                <div className='flex justify-between text-black items-center'>
                                    <p className='text-xs'>{
                                        treatment?.varian?.map(variant => {
                                            return variant.selected && variant.name
                                        })
                                    }</p>
                                    <p className='text-xs'>{
                                        treatment?.varian?.map(variant => {
                                            return variant.selected && variant.process_time_name
                                        })
                                    }</p>
                                </div>

                                <div className='flex justify-between gap-2'>
                                    <Button
                                        onClick={() => showModal(treatment)}
                                        className='bg-white w-full'>Varian</Button>
                                    <Button
                                        onClick={() => showModalTime(treatment)}
                                        className='bg-white w-full'>Time</Button>
                                </div>
                                <Button
                                    onClick={() => handleAddToCart(treatment)}
                                    className='bg-blue-400 text-white w-full'>Add To Cart</Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        return (
            <>
                {laundryType === 'satuan' ? (
                    <div className='flex gap-2'>
                        {/* TYPE ITEM */}

                        <div className='flex flex-col gap-2'>
                            {dataLaundrySatuan.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => handleSelectTypeItem(item?.item_name)}
                                        className={`${item?.active ? 'bg-blue-400 text-white' : 'bg-white text-black-400 hover:bg-gray-200'}
                                                    cursor-pointer  text-center min-w-[100px] min-h-[100px] h-[100px] w-[100px] flex rounded-lg shadow-md flex-col items-center justify-center p-3`}>
                                        {/* <FaBoxOpen className='text-4xl' /> */}
                                        <p className='font-bold'>{item?.item_name}</p>
                                    </div>
                                )
                            })}
                        </div>

                        {/* Service */}
                        <div className='w-full rounded-lg p-3'>
                            <div className='grid grid-cols-8 gap-2 '>
                                {dataLaundrySatuan.map((item, index) => {
                                    return (
                                        item?.active && item?.treatment.map((treatment, index) => {
                                            return (
                                                <CardTreatment treatment={treatment} />
                                            )
                                        })
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='flex gap-2'>
                        {/* TYPE ITEM */}
                        <div className='flex flex-col gap-2'>

                            {dataLaundryKiloan.map((item, index) => {
                                return (
                                    <div key={index} className='bg-white text-blue-400 min-w-[100px] min-h-[100px] h-[100px] w-[100px] flex rounded-lg shadow-md flex-col items-center justify-center p-3'>
                                        <RiScales2Fill className='text-4xl' />
                                        {item?.item_name}
                                    </div>
                                )
                            })}
                        </div>
                        {/* Service */}

                        <div className='w-full rounded-lg '>
                            <div className='grid grid-cols-8 gap-2 '>
                                {Array.from({ length: 1 }).map((_, index) => {
                                    return (
                                        <div className='col-span-2 border-2 bg-white shadow-md rounded-lg bg-white w-full'>
                                            <div className='flex flex-col gap-1'>
                                                <div>
                                                    <img src='https://th.bing.com/th/id/OIP.Z0K6Ft5aeOrgWOZw8kHjvgHaEo?rs=1&pid=ImgDetMain'
                                                        alt='shoes'
                                                        className='w-full h-30 object-cover rounded-t-lg' />
                                                </div>
                                                <div className='flex flex-col p-4 gap-2'>
                                                    <div className='flex justify-between text-black font-bold items-center'>
                                                        <p>Cuci Aja</p>
                                                        <p>7.000</p>
                                                    </div>

                                                    <div className='flex justify-between gap-2'>
                                                        <Button className='bg-white w-full'>Berat</Button>
                                                        <Button className='bg-white w-full'>Time</Button>
                                                    </div>
                                                    <Button className='bg-blue-400 text-white w-full'>Add To Cart</Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}

            </>
        );
    }

    const optionLaundryType = [
        {
            label: 'Satuan',
            key: 'satuan',
            value: 'satuan',
            children: <ChildrenContent />
        },
        {
            label: 'Kiloan',
            key: 'kiloan',
            value: 'kiloan',
            children: <ChildrenContent />
        },
    ]

    const onChange = (e) => {
        console.log(e);
        setLaundryType(e)
    }

    // FUNGSI UNTUK MENGUBAH STATUS SELECTED PADA VARIAN
    const handleChangeSelectedVariant = (variant) => {
        if (laundryType === 'satuan') {
            const newDataLaundrySatuan = dataLaundrySatuan.map(item => {
                return {
                    ...item,
                    treatment: item.treatment.map(treatment => {
                        return {
                            ...treatment,
                            varian: treatment.varian.map(variantItem => {
                                // ubah selected varian yang hanya ada pada selectedVariant
                                // dan cuman ada satu yang selected
                                if (selectedVariants.find(item => item.id === variantItem.id)) {
                                    if (variantItem.id === variant.id) {
                                        return {
                                            ...variantItem,
                                            selected: true
                                        }
                                    } else {
                                        return {
                                            ...variantItem,
                                            selected: false
                                        }
                                    }
                                }
                                return variantItem;
                            })
                        }
                    })
                }
            })
            setDataLaundrySatuan(newDataLaundrySatuan);
            handleCancel();
        }
    }

    useEffect(() => {
        console.log("LaundrySATUAN", dataLaundrySatuan);
    }, [dataLaundrySatuan])

    // FUNGSI UNTUK MENGUBAH STATUS SELECTED PADA TIME PROCESS
    // Tinggal Ganti data pada treatment (deepclean, outsideclean, dll) yang selected
    // jadi bukan dalam variant dari treatment
    const handleChangeSelectedTimeProcess = (processTime) => {
        console.log("processTime ", processTime);
        console.log("selectedVariantsForTime ", selectedVariantsForTime);

        // Ganti data di data laundry variannya
        if (laundryType === 'satuan') {
            const newDataLaundrySatuan = dataLaundrySatuan.map(item => {
                return {
                    ...item,
                    treatment: item.treatment.map(treatment => {
                        return {
                            ...treatment,
                            varian: treatment.varian.map(variantItem => {
                                if (selectedVariantsForTime.find(item => item.id === variantItem.id)) {
                                    if (variantItem.selected) {
                                        variantItem.process_time_name = processTime?.name;
                                        variantItem.process_time_type_cost = processTime?.type_cost;
                                        variantItem.process_time_value_cost = processTime?.value_cost;
                                    } else {
                                        variantItem.process_time_name = defaultProcessTime?.name;
                                        variantItem.process_time_type_cost = defaultProcessTime?.type_cost;
                                        variantItem.process_time_value_cost = defaultProcessTime?.value_cost;
                                    }
                                }
                                return variantItem;
                            })
                        }
                    })
                }
            })
            setDataLaundrySatuan(newDataLaundrySatuan);
        }

        // ganti data di state processingTimeData
        const newProcessingTimeData = processingTimeData.map(item => {
            if (item.id === processTime.id) {
                return {
                    ...item,
                    selected: true,
                }
            } else {
                return {
                    ...item,
                    selected: false,
                }
            }
        })
        setProcessingTimeData(newProcessingTimeData);
        handleCancelTime();

    }

    const ModalVarian = () => {
        return (
            <Modal title="Varian" open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>

                <div className='flex flex-col gap-4'>
                    {selectedVariants.map((variant, index) => {
                        return (
                            <div
                                onClick={() => handleChangeSelectedVariant(variant)}
                                className={`${variant?.selected ? 'bg-blue-400 text-white' : ''} flex shadow-lg rounded-lg justify-between items-center border-b-[1px] p-2 pr-4 cursor-pointer hover:bg-blue-400 hover:text-white`}>
                                <div className='flex gap-2 items-center'>
                                    <div className='p-2 w-[60px] h-[60px] rounded-lg flex justify-center items-center shadow-md'>
                                        <FaShoppingCart className='text-xl' />
                                    </div>
                                    <div>
                                        <p className='font-bold'>{variant.name}</p>
                                    </div>
                                </div>
                                <p className='text-md '>{formatCurrency(parseInt(variant.price))}</p>
                            </div>
                        )
                    })}
                </div>
            </Modal>
        )
    }

    const ModalTimeProcess = () => {
        return (
            <Modal title="Process Time" open={isModalOpenTime} footer={null} onOk={handleOkTime} onCancel={handleCancelTime}>
                <div className='flex flex-col gap-4'>
                    {processingTimeData.map((processTime, index) => {
                        return (
                            <div
                                onClick={() => handleChangeSelectedTimeProcess(processTime)}
                                className={`${selectedVariantsForTime?.find(item => item?.selected === true)?.process_time_name === processTime?.name ? 'bg-blue-400 text-white' : ''}
                                flex shadow-lg rounded-lg justify-between items-center border-b-[1px] p-2 pr-4 cursor-pointer hover:bg-blue-400 hover:text-white`}>
                                <div className='flex gap-2 items-center'>
                                    <div className='p-2 w-[60px] h-[60px] rounded-lg flex justify-center items-center shadow-md'>
                                        <FaShoppingCart className='text-xl' />
                                    </div>
                                    <div>
                                        <p className='font-bold'>{processTime.name}</p>
                                    </div>
                                </div>
                                <p className='text-md '>+ {ProcessTimeCalculationDisplay(processTime, selectedVariantsForTime)}</p>
                            </div>
                        )
                    })}
                </div>
            </Modal>
        )
    }



    // COMPONENT KERANJANG BELANJA
    return (
        <>

            <ModalVarian />
            <ModalTimeProcess />
            <div className='w-full border-t-[1px]'>
                <div className='grid grid-cols-10 gap-3 h-full'>
                    <div className='col-span-7 flex flex-col gap-4'>
                        {/* Radio Button Satuan / Kiloan */}
                        <Tabs centered defaultActiveKey="1" items={optionLaundryType} onChange={onChange} />
                    </div>
                    <ShoppingCart formik={formik} />
                </div>
            </div>
        </>
    );
}

export default Index;