export const customerDataAllModel = [

];

export const customerDataModel = {
    "name": "",
    "phone": "",
    "email": null,
    "password": "",
    "address": "",
    "date_birthday": null,
    "city": "",
    "hobby": null,
    "age": null,
    "working_type": null
  }
  