function cleanPhoneNumber(phone) {
    // Menghapus karakter '+'
    let cleanedPhone = phone.replace('+', '');

    // Mengganti digit pertama '0' dengan '62'
    if (cleanedPhone.charAt(0) === '0') {
        cleanedPhone = '62' + cleanedPhone.substring(1);
    }

    return cleanedPhone;
}


export default cleanPhoneNumber;