import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helper/formatCurrency';
import numeral from 'numeral';
import { useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

const { Option } = Select;

const ModalAddProcessingTime = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {
    const location = useLocation();
    const history = useHistory();

    const [form] = Form.useForm();

    const handleAddProcessingTime = () => {
        form.validateFields().then((values) => {
            if (initialData) {
                putData(`/processing-times/${initialData.id}`, {
                    ...values,
                })
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('Processing Time updated successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/processing-times', {
                    ...values,
                })
                    .then((response) => {
                        if (response.status === 201) {
                            toast.success('Processing Time added successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    };

    useEffect(() => {
        if (initialData) {
            form.setFieldsValue({
                name: initialData.name,
                type_cost: initialData.type_cost,
                value_cost: initialData.value_cost,
            });
        }
    }, [initialData]);

    return (
        <Modal
            title="Add Processing Time"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form form={form} onFinish={handleAddProcessingTime}>
                <Form.Item
                    label="Name"
                    name="name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the processing time name' }]}
                >
                    <Input placeholder="Enter processing time name" />
                </Form.Item>

                
                <Form.Item
                    label="Type"
                    name="type_cost"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please select the type' }]}
                >
                    <Select placeholder="Select type cost">
                        <Option value="percent">Percent</Option>
                        <Option value="nominal">Nominal</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Value"
                    name="value_cost"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please select the value' }]}
                >
                   <Input placeholder="Enter value" />
                </Form.Item>


                <Form.Item style={{ display:'flex', justifyContent:'flex-end' }}>
                    <div className='flex gap-2'>
                    <Button
                        type="default"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button className="items-center flex justify-center text-white bg-blue-400" htmlType="submit">
                        {initialData ? 'Update' : 'Add'}
                    </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default ModalAddProcessingTime;
