export const UserDataModel = {
    "id": 0,
    "name": "",
    "email": "",
    "phone": "",
    "address": "",
    "status": "",
    "role": "",
    "salary": "",
    "salary_date": "",
    "createdAt": "",
    "updatedAt": "",
    "deletedAt": ""
}

export const CustomerDataModel = {
    "id": 0,
    "name": "",
    "phone": "",
    "email": "",
    "address": "",
    "date_birthday": "",
    "city": "",
    "hobby": "",
    "age": "",
    "working_type": "",
    "createdAt": "",
    "updatedAt": "",
    "deletedAt": ""
}
