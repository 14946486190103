import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { fetchData, postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helper/formatCurrency';
import numeral from 'numeral';
import { useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import UploadPhoto from './UploadPhoto';
import { useFormik } from 'formik';
import moment from 'moment';
import { Dayjs } from 'dayjs';

const { Option } = Select;

const ModalAddAbsent = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {
    const location = useLocation();
    const history = useHistory();

    const [employee, setEmployee] = React.useState([]);

    const formik = useFormik({
        initialValues: {
            employee_id: '',
            photo_clock_in: '',
        },
        onSubmit: values => {
            const currentTime = moment().format('HH:mm:ss');
            const statusAbsent = values?.status;

            const newData = {
                ...values,
                employee_id: values.employee_id,
                branch_id: 1,
                date: initialData ? initialData.date : moment().format('YYYY-MM-DD'),
                clock_in: initialData ? initialData.clock_in : currentTime,
                status: statusAbsent,
                description: initialData ? initialData.description : 'Regular Absent',
                photo_clock_in: values.photo_clock_in[0].url,
            }
            if (initialData) {
                putData(`/absents/${initialData.id}`, newData)
                    .then((response) => {
                        toast.success('Absent updated successfully');
                        setOpen(false);
                        formik.resetForm();
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/absents', newData)
                    .then((response) => {
                        formik.setValues();
                        toast.success('Absent added successfully');
                        setOpen(false);
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    })

    const handleAddAbsent = () => {
        if (initialData) {
        } else {
        }

    };

    useEffect(() => {
        fetchData(`/users`)
            .then((res) => {
                const data = res.data.data;
                setEmployee(
                    data.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            item: item
                        }
                    }
                    )
                );
            })
            .catch((err) => {
                console.log(err);
            })

        if (initialData) {
            formik.setValues(initialData);
        }
    }, [initialData]);

    return (
        <Modal
            title={`${initialData ? 'Edit' : 'Add'} Absent`}
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form>
                <Form.Item
                    label="Status"
                    name="status"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the absent name' }]}
                >
                    <Select placeholder="Select status"
                        value={formik.values?.status || ''}
                        onChange={(e) => {
                            formik.setFieldValue('status', e);
                        }}
                    >
                        <Option value="Present">Present (Hadir)</Option>
                        <Option value="Absent">Absent (Tidak Hadir)</Option>
                        <Option value="Sick">Sick (Sakit)</Option>
                        <Option value="Leave">Leave (Izin)</Option>
                    </Select>
                </Form.Item>


                <Form.Item
                    label="Employee"
                    name="employee_id"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}

                >
                    <Select placeholder="Select name"
                        value={formik.values?.employee_id}
                        onChange={(e, option) => {
                            formik.setFieldValue('employee_id', e);
                            formik.setFieldValue('employee_absent', option.item);
                        }}
                    >
                        {employee.map((item) => {
                            return (
                                <Option value={item.id} item={item.item}>{item.name}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <label>Upload Photo</label>
                <UploadPhoto formik={formik} />

                <Button style={{ width: "100%" }} className="items-center flex justify-center text-white bg-blue-400" onClick={() => formik.handleSubmit()}>Absent Now</Button>
            </Form>
        </Modal >
    );
};

export default ModalAddAbsent;
