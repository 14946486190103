import { Button, Link } from "antd";
import ActionSalesOrder from "./ActionSalesOrder";
import * as Yup from "yup";
import { PaymentMethodDisplay, PaymentStatusDisplay } from "../../models/orderData";
import dayjs from "dayjs";
import { formatCurrency } from "../../helper/formatCurrency";
import CardItemService from "./CardItemService";
import CustomerInfo from "../component/CustomerInfo";


export const TRANSACTION_RADIO_OPTION = {
    TRANSACTION: "transaction",
    TRANSACTION_DETAIL: "transaction_detail",
    DELIVERY: "delivery",
    PAYMENT: "payment",
}

export const TRANSACTION_DETAIL_OPTION = {
    ITEMS: "items",
    ADD_ITEM: "add_item",
    EDIT_ITEM: "edit_item",
}

export const TRANSACTION_PAYMENT_OPTION = {
    PAID: "paid",
    UNPAID: "unpaid",
}

export const salesOrderData = [];

export const columnsSalesOrder = [
    {
        title: "Id",
        dataIndex: "id",
        key: "id",
    },

    {
        title: "Invoice",
        dataIndex: "code",
        key: "code",
    },
    {
        title: "Customer",
        dataIndex: "customer_name",
        key: "customer_name",
        render: (text, record) => (
            <>
                <CustomerInfo record={record} />
            </>
        ),
    },
    {
        title: "Item",
        dataIndex: "service",
        key: "service",
        render: (text, record) => (
            <div style={{ width: 'auto', minWidth: '440px' }}>
                <CardItemService record={record} />
            </div>
        ),
    },
    {
        title: "Entry Date",
        dataIndex: "entry_date",
        key: "entry_date",
        render: (text, record) => (
            <label>{dayjs(record.entry_date).format("DD MMM YYYY")}</label>
        ),
    },
    {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
    },
    {
        title: "Total Price",
        dataIndex: "total_price",
        key: "total_price",
        render: (text, record) => (
            <label>{formatCurrency(parseInt(record.total_price))}</label>
        ),
    },
    {
        title: "Payment Method",
        dataIndex: "payment_method",
        key: "payment_method",
        render: (text, record) => (
            <label>{PaymentMethodDisplay(record.payment_method)}</label>
        ),
    },
    {
        title: "Payment Status",
        dataIndex: "payment_status",
        key: "payment_status",
        render: (text, record) => (
            <label>{PaymentStatusDisplay(record.payment_status)}</label>
        ),
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
            <ActionSalesOrder text={text} record={record} />
        ),
    },
];

export const orderDataToDataSourcesSalesOrder = (data) => {
    return data.map((item, index) => {
        return {
            ...item,
            key: index + 1,
            id: item.id,
            code: item.payment.code,
            customer_name: item.customer.name,
            service: item.order_details,
            entry_date: item.entry_date,
            due_date: item.due_date,
            quantity: item.quantity,
            total_price: parseInt(item.payment.total_price) + parseInt(item.payment.total_discount),
            payment_method: item.payment.method,
            payment_status: item.payment.status,
            // total_price: formatCurrency(parseInt(item.payment.total_price)),
            // payment_method: PaymentMethodDisplay(item.payment.method),
            // payment_status: PaymentStatusDisplay(item.payment.status),
        };
    });
}

export const AddOrderDetailColumns = [
    {
        title: "No",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "Item",
        dataIndex: "item",
        key: "item",
    },
    {
        title: "Treatment",
        dataIndex: "treatment",
        key: "treatment",
    },
    {
        title: "Employee",
        dataIndex: "employee",
        key: "employee",
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
            <ActionSalesOrder text={text} record={record} />
        ),
    },
];

export const optionsInvoice = {
    // default is `save`
    method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: 3,
    page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: 0,
        // default is 'A4'
        format: 'A4',
        // default is 'portrait'
        orientation: 'potrait',
    },
    canvas: {
        // default is 'image/jpeg' for better size performance
        mimeType: 'image/png',
        qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
            compress: true
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
            useCORS: true
        }
    },
};

// Skema validasi Yup
export const validationSchema = Yup.object().shape({
    customer_id: Yup.string().required('Customer is required'),
    // branch_id: Yup.string().required('Branch is required'),
    entry_date: Yup.date().required('Entry Date is required'),
    quantity: Yup.number().required('Quantity is required'),
    payment: Yup.object().shape({
        code: Yup.string().required('Payment Code is required'),
        status: Yup.string().required('Payment Status is required'),
        total_discount: Yup.number().required('Total Discount is required'),
        total_price: Yup.number().required('Total Price is required'),
        date: Yup.date().when('status', {
            is: 'paid',
            then: Yup.date().required('Payment Date is required'),
        }),
        method: Yup.string().when('status', {
            is: 'paid',
            then: Yup.string().required('Payment Method is required'),
        }),
        money: Yup.number().when('status', {
            is: 'paid',
            then: Yup.number().required('Money is required'),
        }),
        change: Yup.number().when('status', {
            is: 'paid',
            then: Yup.number().required('Change is required'),
        }),
    }),
    order_details: Yup.array().of(
        Yup.object().shape({
            id: Yup.string().required('Order Detail ID is required'),
            employee_id: Yup.string().required('Employee ID is required'),
            treatment_id: Yup.string().required('Treatment ID is required'),
            item_id: Yup.string().required('Item ID is required'),
            discount_id: Yup.string().required('Discount ID is required'),
            price: Yup.number().required('Price is required'),
            final_price: Yup.number().required('Final Price is required'),
            due_date: Yup.date().required('Due Date is required'),
            status: Yup.string().required('Status is required'),
            description: Yup.string().required('Description is required'),
            shoes_photos: Yup.array().of(
                Yup.object().shape({
                    url_photo: Yup.string().required('Photo is required'),
                })
            ),
        })
    ),
});


export const DisplayProcessTime = (time) => {
    switch (time) {
        case 'regular':
            return 'Regular'
        case 'next_day':
            return 'Next Day'
        case 'same_day':
            return 'Same Day'
        default:
            return 'Regular'
    }
}