import React, { useEffect, useState } from "react";
import { GrServices } from "react-icons/gr";
import { TbShoe } from "react-icons/tb";
import { formatCurrency } from "../../helper/formatCurrency";
import { FaMoneyBillWave, FaCartArrowDown } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";

import { Card, Col, Row, Typography, Button, Modal, Input } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { putData } from "../../helper/api";
import { set } from "date-fns";
import { toast } from "react-toastify";

const { Title } = Typography;

function ShiftDashboard(props) {
    const { dataShift, setShiftDashboardOpen } = props;
    const [statisticData, setStatisticData] = useState();
    const [paid, setPaid] = useState(0);
    const [unpaid, setUnpaid] = useState(0);

    const [openModalEndShift, setOpenModalEndShift] = useState(false);

    const formik = useFormik({
        initialValues: {
            cashInDrawer: 0
        },
        onSubmit: values => {
            setShiftDashboardOpen(true)
            setOpenModalEndShift(false)
        }
    })

    useEffect(() => {
        if (dataShift) {
            setStatisticData(dataShift);
            setPaid(dataShift.paid);
            setUnpaid(dataShift.unpaid);
        }
    }, [dataShift]);

    const statistics2 = [
        {
            name: "Total Revenue",
            value: formatCurrency(parseInt(statisticData?.sales || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Paid",
            value: formatCurrency(parseInt(paid)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Unpaid",
            value: formatCurrency(parseInt(unpaid)),
            icon: <FaMoneyBillWave />,
        },
    ];

    const statistics = [
        {
            name: "Initial Cash",
            value: formatCurrency(parseInt(statisticData?.cash_initial || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Cash In Today",
            value: formatCurrency(parseInt(statisticData?.cash_expected || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Transfer In Today",
            value: formatCurrency(parseInt(statisticData?.bank_transfer || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "QRIS In Today",
            value: formatCurrency(parseInt(statisticData?.qris || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Transaction",
            value: statisticData?.transaction || 0,
            icon: <FaCartArrowDown />,
        },
        {
            name: "Delivery",
            value: statisticData?.delivery || 0,
            icon: <TbTruckDelivery />,
        },
        {
            name: "Item",
            value: statisticData?.item || 0,
            icon: <TbShoe />,
        },
        {
            name: "Service",
            value: statisticData?.service || 0,
            icon: <GrServices />,
        },
    ];


    const handleEndShift = () => {
        putData(`end-shifts/${dataShift.id}`, {
            cash_actual: parseInt(formik.values.cashInDrawer),
        })
            .then((data) => {
                setOpenModalEndShift(false);
                setShiftDashboardOpen(false)
                formik.setFieldValue('cashInDrawer', 0)
                toast.success("Shift Ended");
            })
    }

    return (
        <div>
            <Modal
                title="Shift, 12 Jan 2024"
                open={openModalEndShift}
                onCancel={() => setOpenModalEndShift(false)}
                onOk={() => setOpenModalEndShift(false)}
                footer={null}
            >
                <div style={{ display: 'flex', justifyContent: "space-between", fontSize: "15px" }}>
                    <label>Initial Cash</label>
                    <label>{formatCurrency(parseInt(statisticData?.cash_initial || 0))}</label>
                </div>
                <div style={{ display: 'flex', justifyContent: "space-between", fontSize: "15px" }}>
                    <label>Cash In Today</label>
                    <label>{formatCurrency(parseInt(statisticData?.cash_expected || 0))}</label>
                </div>

                <div style={{ display: 'flex', justifyContent: "space-between", fontSize: "15px" }}>
                    <label>Transfer In Today</label>
                    <label>{formatCurrency(parseInt(statisticData?.bank_transfer || 0))}</label>
                </div>

                <div style={{ display: 'flex', justifyContent: "space-between", fontSize: "15px" }}>
                    <label>QRIS In Today</label>
                    <label>{formatCurrency(parseInt(statisticData?.qris || 0))}</label>
                </div>

                <hr />
                <div style={{ display: 'flex', justifyContent: "space-between", fontSize: "15px" }}>
                    <label>Total Paid</label>
                    <label>{formatCurrency(parseInt(statisticData?.bank_transfer || 0) + parseInt(statisticData?.qris || 0) + parseInt(statisticData?.cash_expected || 0))}</label>
                </div>
                <div style={{ display: 'flex', justifyContent: "space-between", fontSize: "15px" }}>
                    <label>Total Unpaid</label>
                    <label>{formatCurrency(parseInt(statisticData?.sales || 0) - parseInt(statisticData?.bank_transfer || 0) - parseInt(statisticData?.qris || 0) - parseInt(statisticData?.cash_expected || 0))}</label>
                </div>
                <div style={{ display: 'flex', justifyContent: "space-between", fontSize: "15px" }}>
                    <label>Total Expected Cash In Drawer</label>
                    <label>{formatCurrency(parseInt(statisticData?.cash_expected) + parseInt(statisticData?.cash_initial))}</label>
                </div>
                <hr />

                <div className="flex flex-col gap-2">
                    <p style={{ marginTop: "20px", width: "100%", fontSize: '15px', fontWeight: 'bold' }}>Actual Cash in Drawer</p>
                    <Input
                        placeholder="Actual Cash in Drawer"
                        style={{ width: "100%" }}
                        name="cashInDrawer"
                        value={formatCurrency(parseInt(formik.values.cashInDrawer) || 0)}
                        onChange={(event) => {
                            const value = event.target.value;
                            // Mengabaikan karakter selain digit (contoh: comma atau titik)
                            const numericValue = parseFloat(value.replace(/[^\d]/g, ''));

                            // Menangani nilai awal NaN
                            const newValue = isNaN(numericValue) ? 0 : numericValue;

                            formik.setFieldValue('cashInDrawer', newValue);
                        }}
                    />

                    <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleEndShift()}>End Shift</Button>
                </div>
            </Modal>

            <Title level={3}>{`Shift, ${moment(dataShift).format('DD MMMM YYYY')}`}</Title>

            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {statistics2.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={8}
                        className="mb-4"
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18}>
                                        <span>{c.name}</span>
                                        <Title level={3}>
                                            {c.value ? c.value : 0}
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {statistics.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                        className="mb-4"
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18}>
                                        <span>{c.name}</span>
                                        <Title level={3}>
                                            {c.value ? c.value : 0}
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Button className="items-center flex justify-center text-white bg-blue-400 w-[20%]" onClick={() => setOpenModalEndShift(true)}>End Shift</Button>
        </div>
    );
}

export default ShiftDashboard;