import React, { useEffect } from 'react';
import { DisplayProcessTime } from '../Transaction/helper';
// dayjs
import dayjs from 'dayjs';

export const generateHeaderInvoice = (dataOrder) => {
    const { customer } = dataOrder;
    let headerText = `
            KATSIKAT
Jl Grindulu No 24, Bunulrejo, Kec. Blimbing, Kota Malang, Jawa Timur.
0877-9545-2475
--------------------------------
Nama : ${customer.name}
Alamat : ${customer.address}

No Invoice : ${dataOrder.payment.code}
Tanggal : ${dayjs(dataOrder.createdAt).format('DD-MMM-YYYY HH.mm')}
--------------------------------
`;

    return headerText;
}

export const generateFooterInvoice = (dataOrder) => {
    let footerText = `
Harga yang tertera pada Invoice sudah termasuk Garansi 24Jam untuk treatment Cuci dan Garansi 7Hari untuk treatment Repair, Reglue, dan Repaint!

Terima kasih telah mempercayakan Katsikat sebagai jasa cuci barang-barang kesayangmu :).
`;

    return footerText;
};

export const PrintInvoice = async (formikData) => {
    console.log(formikData)
    try {
        const options = {
            filters: [{ name: 'RPP02' }], // Sesuaikan dengan nama printer Anda
            optionalServices: ['e7810a71-73ae-499d-8c15-faa9aef0c3f2'], // Ganti dengan UUID layanan Bluetooth printer Anda
        };

        const device = await navigator.bluetooth.requestDevice(options);
        const server = await device.gatt.connect();
        const service = await server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2'); // Ganti dengan UUID layanan Bluetooth printer Anda
        const characteristic = await service.getCharacteristic('bef8d6c9-9c21-4c9e-b632-bd58c1009f9f'); // Ganti dengan UUID karakteristik Bluetooth printer Anda


        if (characteristic === null) {
            console.error('Bluetooth characteristic is null');
            return false;
        }




        const header = generateHeaderInvoice(formikData);

        // const orders = generateOrderInvoice(formikData);

        const footer1 = generateFooterInvoice(formikData);

        const encoder = new TextEncoder();

        const clearData = async () => {
            await characteristic.writeValue(encoder.encode('')); // Mengirim nilai kosong ke printer untuk membersihkan data sebelumnya
        };

        const printData = async (data) => {
            await clearData(); // Membersihkan data sebelumnya sebelum mencetak
            await characteristic.writeValue(encoder.encode(data)); // Mengirim data baru ke printer
        };

        await printData(header);

        const generateOrderInvoice = async (dataOrder) => {
            let invoiceText = '';
            let total = 0;
            let paymentStatus = dataOrder.payment.status === 'unpaid' ? '(Belum Lunas)' : '(Lunas)';

            if (dataOrder) {
                const { order_details } = dataOrder;


                for (let i = 0; i < order_details.length; i++) {
                    const { treatment, process_time, price, final_price, discount, description } = formikData.order_details[i];
                    const treatmentName = treatment.name;
                    const itemPrice = parseInt(price);
                    const discountedPrice = parseInt(final_price);
                    const discountName = discount.name;
                    const discountPercent = parseInt(discount.discount);
                    const discountPrice = discount.type === 'percent' ? itemPrice * discountPercent / 100 : parseInt(discount.discount);

                    // console.log('Price ', parseInt(price).toString().length)
                    // console.log('SPACE ', price.length)

                    total += discountedPrice;

                    let orderText = `
${treatmentName} - ${DisplayProcessTime(process_time)}
Harga${' '.repeat(32 - 5 - itemPrice.toString().length)}${itemPrice}
${discountPrice === 0 ? ' ' : `${'Potongan'}${' '.repeat(32 - 8 - discountPrice.toString().length - 1)}-${discountPrice}`}
`;

                    await printData(orderText);
                }

                invoiceText += `
--------------------------------
Total ${paymentStatus}${' '.repeat(32 - 6 - paymentStatus.length - total.toString().length)}${total}
--------------------------------`;
            }

            await printData(invoiceText);
        };

        await generateOrderInvoice(formikData);
        await printData(footer1);

        // await printData(header);
        // await printData(orders);
        // await printData(footer1);

        return true;


    } catch (error) {
        console.error(error);
        return false;
    }
}